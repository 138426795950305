@import url(https://use.fontawesome.com/releases/v5.13.0/css/all.css);
.respons{
  padding-right: 0 !important;
  display: inline-block !important;
  text-align: center;
}

@media (min-width: 577px) and (max-width: 767px){
  .elements{
    display: grid !important;
    grid-template-columns: 1fr  1fr !important;
    font-size: small;
  }
}

@media (min-width: 211px) and (max-width: 576px){
  .elements{
    display: grid !important;
    grid-template-columns: 1fr  1fr !important;
    font-size: small;
  }
  .elements i{
    font-size: 1.5rem !important;
  }
}
/* tamaño responsivo del botòn del segundo menú */
@media (min-width: 211px) and (max-width: 576px){
  .secondmenu i{
    font-size: 2.1rem !important;
  }
}

@media (min-width: 211px) and (max-width: 575px) {
  .menu-categories-button i{
      padding-left: 10px !important;
  }
}

@media (min-width: 211px) and (max-width: 767px) {
  .shoppingSearch{
      padding-right: 1px !important;
  }
}

@media (min-width: 768px) {
  .shoppingSearch{
      margin-right: 12px !important;
  }
}

@media screen and (max-width: 768px) {
  .searchItems{
  display:none;
  }
}

@media screen and (min-width: 768px){
  .searchItems i{
  font-size: 25px !important;
  }
}


@media screen and (max-width: 768 px){
  #iconoSearch{
    display: none;
  }
}

/* @media screen and (max-width: 768px) {
  .categorias{
  display:none;
  }
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#alerta {  
  background: rgb(255, 0, 43);
  opacity:1;
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
}

#alerta.fade {
  opacity:0;
}


.sticky-column {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 1;
  width: 100px; /* How can I make this dynamic? */
}

/* Encabezado fijo de tablas*/
.scroll {
  border: 0;
  border-collapse: collapse;
}

.scroll tr {
  display: flex;
}

.scroll td {
  padding: 3px;
  flex: 1 1 auto;
  word-wrap: break;
}

.scroll thead tr:after {
  content: '';
  overflow-y: scroll;
  visibility: hidden;
  height: 0;
}

.scroll thead th {
  flex: 1 1 auto;
  display: block;
}

.scroll tbody {
  display: block;
  width: 100%;
  overflow-y: auto;
  height: 400px;
}


/*ENCABEZADO FIJO DE TABLAS VISTA PROMOCIONES- TABLA VENTAS Y BONIFICACIÓN*/
/* .scrolltable thead tr th { 
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #2d75bd;
}

.scrolltable table-responsive { 
  height:200px;
  overflow:scroll;
} */


/* .scrollPromo {
  border: 0;
  border-collapse: collapse;
}

.scrollPromo tr {
  display: flex;
} 

.scrollPromo td {
  padding: 3px;
  flex: 1 auto;
  word-wrap: break;
}

.scrollPromo thead tr:after {
  content: ''; 
  overflow-y: scroll;
   visibility: hidden; 
   height: 0; 
}

.scrollPromo thead th {
  flex: 1 auto; 
  display: block;
}

.scrollPromo tbody {
  width: 100%;
  display: block;
  overflow-y: auto;
  height: 290px;
}  */

.modal #HeaderModalVenta{
  cursor: move;
}

.details{
  margin: 0;
  background-color: white;
  text-align: center;
  color: #f44336 !important;
  padding: 3px;
  font-weight: bold;  cursor:pointer;
  font-size: medium;
  display: flex;
  justify-content: center;
}


.popsDetails {
  background-color: blue !important;
  width: 16px;
  height: 18px;
  color: #f44336 !important;
}

/* responsivo de imagenes e iconos del Footer */
@media (min-width: 211px) and (max-width: 767px){
  .styleFooter img{
    width: 25% !important;
    height: 15% !important;
  }

  .styleFooter i{
    font-size: 1.3rem !important;
  }

}

/* detalles de los items en la modal */


@media (min-width: 992px) and (max-width: 1022px){
  .detailisModal{
    height: 150px !important;
  }
}

@media (min-width: 1023px) and (max-width: 2024px){
  .detailisModal{
    height: 240px !important;
  }
}

@media (min-width: 1300px) and (max-width: 1520px){
  .detailisModal{
    height: 300px !important;
  }
}

@media (min-width: 1521px){
  .detailisModal{
    height: 400px !important;
  }
}
.sans-serif { font-family: Helvetica, 'Helvetica next', helvetica, arial, sans-serif; }
/* SECCION DE CONTACTO --BARRA AZUL*/
.contact {
  height: 44.5px;
  /* background: -webkit-linear-gradient(45deg, rgba(0,118,187,1) 0%, rgba(41,91,144,1) 100%); */
  background: rgb(197, 88, 48);
}

.text-navbar {
  font-weight: bold;
  font-size: 1.2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.text-navbar:hover{
  background-color: #efefef !important;
  color: #0078C0 !important;
}

@media (min-width: 768px) { 
  .text-navbar {
    font-weight: bold;
    font-size: 1.2rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }
}
 
@media (min-width: 1024px) {
  .text-navbar {
    font-weight: bold;
    font-size: 1.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
 }

@media (min-width: 1920px) and (max-width: 3500px){
  .contact .phone{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 40px;
    margin-left: auto;
    font-family: 'Helvetica';
    font-size: 17pt;
  }

  .contact .suc{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 136.3px !important;
    font-family: 'Helvetica';
    font-size: 17pt;
  }
}

@media (min-width: 1200px) and (max-width:1919px){
  .contact .phone{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 32.8px;
    margin-left: auto;
    font-family: 'Helvetica';
    font-size: 17pt;
  }

  .contact .suc{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 64.3px;
    font-family: 'Helvetica';
    font-size: 17pt;
  }
}

@media (min-width: 992px) and (max-width:1199px){
  .contact .phone{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 32.8px;
    margin-left: auto;
    font-family: 'Helvetica';

  }

  .contact .suc{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 64.3px;
    font-family: 'Helvetica';

  }
}

@media (min-width: 768px) and (max-width:991px){
  .contact .phone{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 32.8px;
    margin-left: auto;
    font-family: 'Helvetica';
    font-size: 14pt;
  }

  .contact .suc{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 64.3px;
    font-family: 'Helvetica';
    font-size: 14pt;
  }
}

@media (min-width: 576px) and (max-width:767px){
  .contact{
    height: 60px;
    display: grid !important;
    grid-template-rows: 1fr 1fr !important;
    font-family: 'Helvetica';
    font-size: 14pt;
  }

  .contact .phone{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 32.8px;
    text-align: center;
    font-family: 'Helvetica';
    font-size: 14pt;
  }

  .contact .suc{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 64.3px;
    text-align: center;
  }
}

@media (min-width: 211px) and (max-width:575px){
  .contact{
    height: 60px;
    display: grid !important;
    grid-template-rows: 1fr 1fr !important;
    font-family: 'Helvetica';
    font-size: 14pt;
  }

  .contact .phone{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 32.8px;
    margin-left: auto;
    font-size: 14pt;
  }

  .contact .suc{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: auto;
  }
}

/* FIRST NAVBAR */
.imgAvatar img{
  width: 60px !important;
  height: 60px !important;
}
.elements .shooping span{
  vertical-align: middle !important;
}

.shopp .ico-nav-shearch{
  vertical-align: middle !important;
}

.shooping img{
  width: 55px;
}

@media (min-width: 1920px) and (max-width: 3500px){
  .primerNav{
    height: 128px;
  }

  .elements .menu-categories-button{
    margin-left: auto !important;
  }

  .elements .shooping{
    margin-right: auto !important;
  }

  .elements .menu-categories-button #buttonMenu{
    width: 65.596px !important;
    height: 48.283px  !important;
    margin-right: 5px;
  }

  .menu-categories-button p img{
    width: 237.146px !important;
    height: 82.199px  !important;
  }

  .menu-categories-button p{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important; 
  }

  .shooping{
    /* width: 58.55px;
    height: 58.55px;
    border: 3px solid -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    border-radius: 50%;
    background: -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    color: white;
    padding-top: 15px;
    padding-left: 15px; */
  }

  #IconUser{
    width: 58.55px;
    height: 58.55px;
    border: 3px solid #8c8c8c !important;
    border-radius: 50%;
    background: #8c8c8c !important;
    color: white;
    padding-top: 13px;
  }
  
  .elements li{
    margin-top: auto !important;
    margin-bottom: auto;
  }
  
  .NombreUsuario{
    text-align: left;
  }

  .buttonSearchAdvanced img{
    display: none;
  }

  .searchItems{
    display:block;
    }
}

@media (min-width: 1200px) and (max-width:1919px){
  .primerNav{
    height: 128px;
  }

  .elements .menu-categories-button{
    margin-left: auto !important;
  }

  .elements .shooping{
    margin-right: auto !important;
  }
  
  .elements .menu-categories-button #buttonMenu{
    width: 61.536px !important;
    height: 46.536px  !important;
    margin-right: 8.8px;
  }

  .menu-categories-button p img{
    width: 183.146px !important;
    height: 70.199px  !important;
  }

  .shooping{
    /* width: 51.35px;
    height: 51.35px;
    border: 2.28px solid -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    border-radius: 50%;
    background: -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    color: white;
    padding-top: 10px;
    padding-left: 10px; */
  }

  #IconUser{
    width: 51.35px;
    height: 51.35px;
    border: 2.28px solid #8c8c8c !important;
    border-radius: 50%;
    background: #8c8c8c !important;
    color: white;
    padding-top: 5.8px;
  }
  
  .elements li{
    margin-top: auto !important;
    margin-bottom: auto;
  }
  
  .NombreUsuario{
    text-align: left;
  }

  .buttonSearchAdvanced img{
    display: none;
  }

  .searchItems{
    display:block;
    }
}

@media (min-width: 992px) and (max-width:1199px){
  .primerNav{
    height: 90px;
  }

  .elements .menu-categories-button{
    margin-left: auto !important;
  }

  .elements .shooping{
    margin-right: auto !important;
  }
  
  .elements .menu-categories-button #buttonMenu{
    width: 43.083px !important;
    height: 31.083px  !important;
    margin-right: 5px;
  }

  .menu-categories-button p img{
    width: 145.146px !important;
    height: 52.999px  !important;
  }

  .shooping{
    /* width: 51.35px;
    height: 51.35px;
    border: 2.28px solid -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    border-radius: 50%;
    background: -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    color: white;
    padding-top: 10px;
    padding-left: 10px;
    margin-top: auto !important;
    margin-bottom: auto !important; */
  }

  #IconUser{
    width: 51.35px;
    height: 51.35px;
    border: 2.28px solid #8c8c8c !important;
    border-radius: 50%;
    background: #8c8c8c !important;
    color: white;
    padding-top: 5.8px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elements li{
    margin-top: auto !important;
    margin-bottom: auto;
  }
  
  .NombreUsuario{
    text-align: left;
  }

  .buttonSearchAdvanced img{
    display: none;
  }

  .searchItems{
    display:block;
    }
}

@media (min-width: 768px) and (max-width:991px){
  .primerNav{
    height: 80px;
  }
  
  .elements .menu-categories-button{
    margin-left: auto !important;
  }

  .elements .shooping{
    margin-right: auto !important;
  }

  .elements .menu-categories-button #buttonMenu{
    width: 40.336px !important;
    height: 29.336px  !important;
    margin-right:5px;
  }

  .elements .menu-categories-button{
    padding-right: 4px !important;
    padding-left: 0px !important;
  }

  .elements .navbar-brand{
    margin-right: 3px !important;
  }

  .menu-categories-button p img{
    width: 138px !important;
    height: 50px  !important;
  }

  .shooping{
    /* width: 51.35px;
    height: 51.35px;
    border-radius: 50%;
    background: -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    color: white;
    padding-top: 12px;
    padding-left: 10px;
    margin-top: auto !important;
    margin-bottom: auto !important; */
  }

  #IconUser{
    width: 51.35px;
    height: 51.35px;
    border-radius: 50%;
    background: #8c8c8c !important;
    color: white;
    padding-top: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elements li{
    margin-top: auto !important;
    margin-bottom: auto;
  }
  
  .NombreUsuario{
    text-align: left;
  }
}

@media (min-width: 645px) and (max-width:767px){
  .elements{
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr !important;
  }

  .elements .menu-categories-button{
    margin-left: auto !important;
  }

  .elements .shooping{
    margin-right: auto !important;
  }

  .elements .menu-categories-button #buttonMenu{
    width: 50px !important;
    height: 38px  !important;
  }

  .menu-categories-button p img{
    width: 130px !important;
    height: 49px  !important;
  }

  .shooping img{
    width: 45px;
  }

  .shooping{
    /* width: 51.35px;
    height: 51.35px;
    border: 2.28px solid -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    border-radius: 50%;
    background: -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    color: white;
    padding-top: 10px;
    padding-left: 10px;
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-left:8px; */
  }
  
  .elements li{
    margin-top: auto !important;
    margin-bottom: auto;
  }

  .buttonSearchAdvanced img{
    display: block;
    width: 80% !important;
    margin-right: 15%
  }
}

@media (min-width: 576px) and (max-width:644px){
  .elements{
    display: grid;
    grid-template-columns: auto auto auto !important;
  }

  .elements .menu-categories-button{
    margin-left: auto !important;
  }

  .elements .shooping{
    margin-right: auto !important;
  }

  .elements .menu-categories-button{
    margin-left: 1px !important;
  }

  .elements .shooping{
    margin-right: 1px !important;
  }

  .elements .menu-categories-button #buttonMenu{
    width: 40px !important;
    height: 30px  !important;
  }

  .menu-categories-button p img{
    width: 120px !important;
    height: 43px  !important;
  }

  .shooping img{
    width: 45px;
  }

  .shooping{
    /* width: 51.35px;
    height: 51.35px;
    border: 2.28px solid -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    border-radius: 50%;
    background: -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    color: white;
    padding-top: 10px;
    padding-left: 10px;
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-left:8px; */
  }
  
  .elements li{
    margin-top: auto !important;
    margin-bottom: auto;
  }

  .buttonSearchAdvanced img{
    display: block;
    width: 80% !important;
    margin-right: 15%
  }
}

@media (min-width: 300px) and (max-width:575px){
  .elements{
    display: grid !important;
    grid-template-columns: auto auto auto !important;
  }
  .elements{
    margin-left: 5px !important;
  }

  .shooping img{
    width: 37px;
  }

  .elements .shooping{
    margin-right: 50px !important;
  }
  
  .elements .shooping{
    margin-right: auto !important;
  }

  .elements .navbar-brand{
    margin-right: 2px !important;
    margin-left: 2px !important;
  }

  .elements .menu-categories-button #buttonMenu{
    width: 30px !important;
    height: 25px  !important;
  }

  .menu-categories-button p img{
    width: 80px !important;
    height: 30px;
    margin: 0rem !important;
    padding: 0rem !important;
  }

  .shooping{
    /* width: 35.35px;
    height: 35.35px;
    border-radius: 50%;
    background: -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    color: white;
    padding-left: 1px;
    padding-top: 5px;
    margin-top: auto !important;
    margin-bottom: auto !important; */
  }

  .shooping{
    font-size: 1.5rem !important;
  }
  
  .elements li{
    margin-top: auto !important;
    margin-bottom: auto;
  }

  .buttonSearchAdvanced img{
    display: block;
    width: 100px!important;
  }

  .ico-nav-shearch{
    font-size: 1.5rem !important;
  }
}


.respons{
  padding-right: 0 !important;
  display: inline-block !important;
  text-align: center;
}
/* @media (min-width: 211px) and (max-width:360px){
  .ico-nav-shearch{
    font-size: 1.4rem !important;
  }

  .shooping{
    font-size: 1.4rem !important;
  }

  .buttonSearchAdvanced img{
    width: 88px;
  }
} */

/* @media (min-width: 361px) and (max-width:415px){
  .ico-nav-shearch{
    font-size: 1.5rem !important;
  }

  .shooping{
    font-size: 1.5rem !important;
  }

  .buttonSearchAdvanced img{
    width: 100px;
  }

} */

/* @media (min-width: 416px) and (max-width:575px){
  .ico-nav-shearch{
    font-size: 1.9rem !important;
  }

  .shooping{
    font-size: 1.9rem !important;
  }

  .buttonSearchAdvanced img{
    width: 120px;
  }

} */


/* @media (min-width: 280px) and (max-width: 576px){
  .elements{
    display: grid !important;
    grid-template-columns: 2fr 2fr 2fr  !important;
  }
} */

/* @media screen and (max-width: 768px) {
  .searchItems{
  display:none;
  }
} */

/* SEGUNDA NAV */
.navbar-two .menuImages li img{
  /* vertical-align: sub !important; */
  margin-top: auto !important;
}
.navbar-two .menuImages li{
  margin-top: auto !important;
}

@media screen and (max-width: 991px){
  .miCuenta{
      display: none !important;
  }
}

@media screen and (max-width: 991px){
  ul .miCuenta{
    display: none !important;
  }
}

@media (min-width: 1920px) and (max-width: 3000px){
  .navbar-two {
    width:1192.6x !important;
  }

  .navbar-two .menuImages{
    /* margin-top: auto !important; */
    /* margin-bottom: auto !important; */
  }

  .navbar-two li img{
    max-width: 200px;
    max-height: 60px;
  }
}

@media (min-width: 1200px) and (max-width:1919px){
  .navbar-two {
    width:1192.6x !important;
    height: 60px;
  }
  .navbar-two .menuImages li{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .navbar-two .menuImages img{
    width: 170px;
  }
}

@media (min-width: 992px) and (max-width:1199px){
  .navbar-two {
    width:1192.6x !important;
    height: 60px;
  }
  .navbar-two .menuImages li{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .navbar-two .menuImages img{
    width: 100px;
  }

}
/* @media (min-width: 1030px) and (max-width: 1110px){
  .navbar-two img{
    width: 170px !important;
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}

@media (min-width: 992px) and (max-width: 1029px){
  .navbar-two img{
    width: 160px !important;
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
} */

/* .nav{
    @media (min-width: 428px) and (max-width:1200px) {
        margin-left:10;
        padding-left: 2 rem;
        align-self: right;
        display: inline;
        padding: left 0;
        margin-right: 80;

    }
} */

/* MENU CATEGORIAS */
@media (min-width: 1920px) and (max-width:3000){
  #menuCategories{
    padding-top: 150px !important;
  }
}

@media (min-width: 1200px) and (max-width:1919px){
  #menuCategories{
    padding-top: 150px !important;
  }
}

@media (min-width: 992px) and (max-width:1199px){
  #menuCategories{
    padding-top: 100px !important;
  }
}

@media (min-width: 768px) and (max-width:991px){
  #menuCategories{
    padding-top: 77px !important;
  }
}

@media (min-width: 645px) and (max-width:767px){
  #menuCategories{
    padding-top: 70px !important;
  }
}

@media (min-width: 576px) and (max-width:644px){  
  #menuCategories{
    padding-top: 60px !important;
  }
}

@media (min-width: 300px) and (max-width:575px){
  #menuCategories{
    padding-top: 45px !important;
  }
}


/* @media (min-width: 992px) and (max-width: 2500px){
  #menuCategories{
    padding-top: 67px;
  }
}

@media (min-width: 768px) and (max-width: 991px){
  #menuCategories{
    padding-top: 68px;
  }
}

@media (min-width: 320px) and (max-width: 767px){
  #menuCategories{
    padding-top: 68px;
  }
} */

@media (max-width: 767px) {
    .hidden-mobile {
      display: none;
    }
  }
  
.c{
    border-color:red ;
}

@media screen and (min-width: 1024px) {
  .marq {
      width: 120px;
      margin: auto; 
      display: inline-block;
      overflow: hidden; 
      white-space: nowrap; 
      color: #0078C0 !important;
      font-weight: bold !important;
  }
}

@media (min-width: 992px) and (max-width: 1023px){
  .marq {
      width: 120px;
      margin: auto; 
      display: inline-block;
      overflow: hidden; 
      white-space: nowrap; 
      color: #0078C0 !important;
      font-weight: bold !important;
  }
}

@media (min-width: 768px) and (max-width: 991px){
  .marq {
      width: 100px;
      margin: auto; 
      display: inline-block;
      overflow: hidden; 
      white-space: nowrap; 
      color: #0078C0 !important;
      font-weight: bold !important;
  }
}

.orig {
  display: inline-block; 
  -webkit-transform: translatex(0); 
          transform: translatex(0); 
  -webkit-animation: marquesina 10s infinite normal linear 1s; 
          animation: marquesina 10s infinite normal linear 1s; 
}

@-webkit-keyframes marquesina {
  0% {-webkit-transform: translatex(0);transform: translatex(0); }
  50% {-webkit-transform: translatex(-30%);transform: translatex(-30%); }
}

@keyframes marquesina {
  0% {-webkit-transform: translatex(0);transform: translatex(0); }
  50% {-webkit-transform: translatex(-30%);transform: translatex(-30%); }
}

/* modal búsqueda avanzada */
#searchModal .modal-header{
  color: white !important;
}

/* --------------------------------------- */

@media screen and (min-width:576px){
  .shooping .cont-cart{
    font-weight: normal !important;
    font-size: 2rem !important;
    padding-left: 8px;
  }  
}

@media screen and (max-width:575px){
  .shooping .cont-cart{
    font-weight: normal !important;
    font-size: 1.5rem !important;
    padding-left: 2px;
  }  
}
@media (min-width: 1920px) and (max-width: 3000px){
    .inputSearchItems{
        width: 884.872px !important;
        height: 68.566px !important; 
    }

    .inputSearchItems input::-webkit-input-placeholder{
        font-style: normal !important;
        color: #ced4da !important;
        font-family: 'Helvetica', 'Heavy';

    }

    .inputSearchItems input:-ms-input-placeholder{
        font-style: normal !important;
        color: #ced4da !important;
        font-family: 'Helvetica', 'Heavy';

    }

    .inputSearchItems input::-ms-input-placeholder{
        font-style: normal !important;
        color: #ced4da !important;
        font-family: 'Helvetica', 'Heavy';

    }

    .inputSearchItems input::placeholder{
        font-style: normal !important;
        color: #ced4da !important;
        font-family: 'Helvetica', 'Heavy';

    }
    
    .inputSearchItems input{
        border-top-left-radius: 15px !important;
        border-bottom-left-radius: 15px !important;
        font-family: 'Helvetica';

    }

    .buttonIconSearch i{
        width: 35.246px;
    }

    .buttonAvanzada{
        border-top-right-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
        text-align: center;
        font-family: 'Helvetica';

    }

    .divisior::before{
        position:absolute;
        content: "";
        z-index: 9;
        border-left: 3px solid rgba(34,36,38,.15);
        height: calc(90% - 1.8rem);
    }

    .divisior::before{
        top: 25% !important;
    }
}

@media (min-width: 1200px) and (max-width: 1919px){
    .inputSearchItems{
        width: 500.872px !important;
        height: 61.366px !important; 
    }

    .inputSearchItems input::-webkit-input-placeholder{
        font-style: normal !important;
        color: #ced4da !important;
    }

    .inputSearchItems input:-ms-input-placeholder{
        font-style: normal !important;
        color: #ced4da !important;
    }

    .inputSearchItems input::-ms-input-placeholder{
        font-style: normal !important;
        color: #ced4da !important;
    }

    .inputSearchItems input::placeholder{
        font-style: normal !important;
        color: #ced4da !important;
    }
    
    .inputSearchItems input{
        border-top-left-radius: 15px !important;
        border-bottom-left-radius: 15px !important;
    }

    .buttonIconSearch i{
        width: 35.246px;
    }

    .buttonAvanzada{
        border-top-right-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
        text-align: center;
    }

    .divisior::before{
        position:absolute;
        content: "";
        z-index: 9;
        border-left: 3px solid rgba(34,36,38,.15);
        height: calc(90% - 1.8rem);
    }

    .divisior::before{
        top: 25% !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px){
    .inputSearchItems{
        width: 428.872px !important;
        height: 54.166px !important;
        vertical-align: middle !important;
    }

    .inputSearchItems input::-webkit-input-placeholder{
        font-style: normal !important;
        color: #ced4da !important;
    }

    .inputSearchItems input:-ms-input-placeholder{
        font-style: normal !important;
        color: #ced4da !important;
    }

    .inputSearchItems input::-ms-input-placeholder{
        font-style: normal !important;
        color: #ced4da !important;
    }

    .inputSearchItems input::placeholder{
        font-style: normal !important;
        color: #ced4da !important;
    }
    
    .inputSearchItems input{
        border-top-left-radius: 15px !important;
        border-bottom-left-radius: 15px !important;
        height: 50px !important;
    }

    .buttonIconSearch{
        height: 50px !important; 
    }

    .buttonIconSearch i{
        font-size: 1.5rem;
    }

    .buttonAvanzada{
        border-top-right-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
        text-align: center;
        height: 50px !important;
    }

    /* .divisior::before{
        position:absolute;
        content: "";
        z-index: 9;
        border-left: 3px solid rgba(34,36,38,.15);
        height: calc(90% - 1.8rem);
    }

    .divisior::before{
        top: 35% !important;
    } */
}

@media (min-width: 768px) and (max-width: 991px){
    .inputSearchItems{
        width: 256.872px !important;
        margin-right: auto;
        margin-left: auto;
    }

    .inputSearchItems input::-webkit-input-placeholder{
        font-style: normal !important;
        color: #ced4da !important;
    }

    .inputSearchItems input:-ms-input-placeholder{
        font-style: normal !important;
        color: #ced4da !important;
    }

    .inputSearchItems input::-ms-input-placeholder{
        font-style: normal !important;
        color: #ced4da !important;
    }

    .inputSearchItems input::placeholder{
        font-style: normal !important;
        color: #ced4da !important;
    }
    
    .inputSearchItems input{
        border-top-left-radius: 15px !important;
        border-bottom-left-radius: 15px !important;
        height: 50px !important;
    }

    .buttonIconSearch i{
        font-size: 1.2rem;
    }

    .buttonAvanzada{
        border-top-right-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
        text-align: center;
    }

    /* .divisior::before{
        position:absolute;
        content: "";
        z-index: 9;
        border-left: 3px solid rgba(34,36,38,.15);
        height: calc(90% - 1rem);
    }

    .divisior::before{
        top: 25% !important;
    } */
}


/* Seccion de cambios en el front-end*/
.card {
  border-radius: 15px !important;
}
.card:hover{
  box-shadow: 0px 2px 10px rgba(49, 40, 40, 0.5);
}
/* Centrado de caruceles */
.react-multi-carousel-item{
  display: flex;
  justify-content: center;
}

.linkFilter{
  color: black;
}

@media only screen and (min-width: 900px){
  #sidebar{
    background-color: #ffffffd7 !important;
    position: inherit;
    border: 2px solid #d2d2d2;
    border-radius: 0.7rem;
  }
}
@media (min-width: 981px) and (max-width: 1283px){
  .colmd{
    flex: 0 0 24% !important;
    max-width: 24% !important;
  }
  .colmd-r{
    flex: 0 0 76% !important;
    max-width: 76% !important;
  }
}

@media (min-width: 826px) and (max-width: 981px){
  .colmd{
    flex: 0 0 23% !important;
    max-width: 23% !important;
  }
  .colmd-r{
    flex: 0 0 77% !important;
    max-width: 77% !important;
  }
}
@media (min-width: 768px) and (max-width: 825px){
  .colmd{
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .colmd-r{
    flex: 0 0 59% !important;
    max-width: 59% !important;
  }
}
/* Fin cambios  */

/* @media (min-width: 826px) and (max-width: 981px){
  .colmd{
    flex: 0 0 28% !important;
    max-width: 30% !important;
  }
} */
.toggle-btn
{
display: none;
}

.toggle-btn.active{
  display: none;
}
#sidebar {
  background-color: #ffffffd7 !important;
  position: inherit;
  border: 2px solid #d2d2d2;
  border-radius: 0.7rem;
}
#sidebar.active {
  background-color: #ffffffd7 !important;
  position: inherit;
  border: 2px solid #d2d2d2;
  border-radius: 0.7rem;
}

@media only screen and (max-width: 767px){

  .colmd{
    max-height: 30rem !important;
  }
  #sidebar {
    position: inherit;
    z-index: 1003;
    width: auto;
    max-width: 100%;
    height: 0;
    background-color: #fcfcfc !important;
    border: 2px solid #d2d2d2;
    left: -200%;
    -webkit-transition: all 500ms linear;
    transition: all 500ms linear;
    -webkit-transition: left -200% 500ms e;
    transition: left -200% 500ms e;
    -webkit-animation-name: salida;
            animation-name: salida;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    animation-direction:reverse ;

  }

  @-webkit-keyframes salida {
    0%{height: auto; left: -200%;}
    90%{height: auto; background-color: #89c33f;}
    100%{height: 0; left: 0; background-color: #ff0808;}
  }

  @keyframes salida {
    0%{height: auto; left: -200%;}
    90%{height: auto; background-color: #89c33f;}
    100%{height: 0; left: 0; background-color: #ff0808;}
  }

  #sidebar.active {
    left: 0;
    position: inherit;
    height: auto;
    max-height: 100%;
    z-index: 1;
    background-color: #fcfcfc !important;
    border: 2px solid #d2d2d2;
    -webkit-transition: all 500ms linear;
    transition: all 500ms linear;
    overflow-y: scroll;
    -webkit-animation-name: salida;
            animation-name: salida;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    animation-direction:reverse ;
  }
  .toggle-btn
 {
  display: inline;
  top:-50px;
  left: 0px;
  position: inherit;
  background-color:#ffffff00 !important;
 }
 .toggle-btn.active
 {
  display: inline;
  background-color:#ffffff00 !important;
  color: #35759b;
 }
}

/* Traido desde le archivo ItemList.js*/
.cardImg{
  width: 180px !important;
  height: 180px;
  background-color: transparent;
  cursor: pointer;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
}

/* ----------------------------------- */

.precioPromocion{
  text-decoration: line-through !important;
  color: grey;
  font-size: large;
}

#ItemsSlider .card{
    background: white !important;
    border: none !important;
    border-radius: 15px;
  }
  
  #ItemsSlider  .card-body {
    font-family: 'Helvetica';
    background: white;
    border-top-style: solid;
    border-top-color: #efefef !important;
  }
  
  #ItemsSlider .card-footer{
    background: transparent !important;
    margin-top: auto;
  }
  
  .cantidad input{
    border: transparent;
    background: #efefef;
    border-radius: 10px;
  }
  
  .cantidad input::-webkit-input-placeholder{
    font-style: normal !important;
  }
  
  .cantidad input:-ms-input-placeholder{
    font-style: normal !important;
  }
  
  .cantidad input::-ms-input-placeholder{
    font-style: normal !important;
  }
  
  .cantidad input::placeholder{
    font-style: normal !important;
  }
  
  .botonAgregar button{
    width: 123.5px !important;
    height: 45px;
    background: #89c33f;
    border: transparent;
    color: white;
    font-weight: bold;
    border-radius: 10px;
  }
  
  .botonAgregar button:hover{
    background: #89c33f;
    border: transparent;
    color: white;
    font-weight: bold;
    border-radius: 10px;
  }
  
  @media (min-width: 1920px) and (max-width: 3500px){
    .SliderItems{
      width: 1630px !important;
      margin-left: auto;
      margin-right: auto;
  
    }
  }
  
  @media (min-width: 992px) and (max-width: 1119px){
    .SliderItems{
      width: 910x !important;
      margin-left: auto;
      margin-right: auto;
    }
  }

@media (min-width: 211px) and (max-width: 575px){
  .SliderItems .react-multi-carousel-item .card{
    margin-right: 4.5px !important;
    margin-left: 4.5px !important;
  }
}
  
  .linkFilter:hover {
    -webkit-text-decoration: underline 3px;
            text-decoration: underline 3px;
  }
  
  /* SECCION DE MARCAS */
  .marcas{
    height: 272px;
  }
  
  .marcas img{
    width: 1627px ;
  }
  
  /* @media (min-width: 992px) and (max-width: 2500px){
    .imageCard .card{
        width: 16rem !important;
    }
    .imageCard .titleCard{
        width: 15rem !important;
        background: red !important;
        font-size: 22px;
        transform: rotate(-30deg);
        text-align: center;
        margin: 20px 13px 0px -45px; */
        /* border-bottom-left-radius: -80px -85px; */     
    /* }
  
    .imageCard img{
      width: 15rem !important;
      height: 15rem !important;
    }
  } */
  
  /* @media (min-width: 768px) and (max-width: 992px){
    .imageCard .card{
        width: 20rem !important;
    }
    .imageCard .titleCard{
        width: 19rem !important;
        background: red !important;
    }
  
    .imageCard img{
      width: 19rem !important;
      height: 19rem !important;
    }
  } */
  
  /* tamaño de imagen de cards responsive*/
      
  /* @media (min-width: 576px) and (max-width: 767px){
      .imageCard .card{
          width: 20rem !important;
      }
      .imageCard .titleCard{
          width: 19rem !important;
          background: red !important;
      }
      .imageCard img{
        width: 19rem !important;
        height: 19rem !important;
      }
    }
  
  @media (min-width: 211px) and (max-width: 575px){
    .imageCard .card{
        width: 15rem !important;
    }
    .imageCard .titleCard{
        width: 13rem !important;
        background: red !important;
        transform: rotate(-30deg);
        text-align: center;
        margin: 20px 15px 0px -40px;
    }
    .imageCard img{
      width: 14rem !important;
      height: 14rem !important;
    }
  } */
  
/*estio cinta "PROMOCIÓN" */
.ribbon {
  position: absolute;
  left: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 250px; 
  height: 250px;
  text-align: right;
  cursor: pointer !important;
}

.ribbon span {
  font-size: 18px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 30px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 170px;
  height: 30px;
  display: block;
  background: red !important;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 35px; left: -35px;
}
/* .ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  color: black !important;
  border-left: 3px solid red;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid red;
} */
/* .ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  color: black !important;
  border-left: 3px solid transparent;
  border-right: 3px solid red;
  border-bottom: 3px solid transparent;
  border-top: 3px solid red;
} */

/* blink {
  animation: blinker 1s infinite;
  color: black;
}

@keyframes blinker {  
  0% { color: white; }
  50% { color: black; }
  100% { color: white; }
} */

/* .nombreArt{
  text-transform: uppercase !important;
} */

.ItemName{
  text-transform: uppercase;
}

.ItemName p{
  padding-right: 25px !important;
}
/* imagenes de carrito en la card de los artículos */
/* .icoCarrito img{
  width: 45px;
  margin-left: 15px;
}

.detailsItemList .card-body{
  height: 156px !important;
  background: red !important;
} */

/* imagenes de carrito en la card de los artículos */
.icoCarrito img{
  width: 45px;
  margin-left: 10px;
}

/* estilos de card en forma de lista */
.listCards .detailsItemList .card-footer .botonAgregar, .botonAgregarYellow, .botonAgregarAgotado{
  justify-content: center !important;
  text-align: center;
  margin: auto !important;
 }
 .listCards {
   border-radius: 15px;
   background: #FFF !important;
 }
 
 .listCards .card-body{
   border-top: #FFF !important;
 }

 @media (min-width: 1920px) and (max-width: 3500px){
   .listCards{
     width: 100%;
     /* width: 740px;
     height: 305.500px; */
   }
   
  .listCards .imagenArticulo .img{
    background: transparent !important;
  }

  .listCards .precioCard .precios .descuento{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: auto !important;
  }
 }
 
 @media (min-width: 1200px) and (max-width:1919px){
   .listCards{
     width: 100%;
     /* width: 740px;
     height: 305.500px; */
   }

   .precioCard .precios .descuento{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: auto !important;
  }
 }
 
 @media (min-width: 992px) and (max-width:1199px){
   .listCards{
     width: 100%;
     /* width: 700px;
     height: 305.500px; */
     margin-left: 65px !important;
     margin-right: auto !important;
   }

   .listCards .precioCard .precios .descuento{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: auto !important;
    font-size: 18px !important;
  }
 }
 
 @media (min-width: 768px) and (max-width:991px){
   .listCards{
     width: 100%;
     /* height: 305.500px; */
     margin-left: 100px !important;
     margin-right: auto !important;
   }
 
   .imagenArticulo{
     /* height: 150px !important; */
     margin: auto !important;
   }
 
   .precioCard .precios .descuento{
     padding-left: 0px !important;
     padding-right: 0px !important;
     font-size: 12px !important;
     margin: auto !important;
   }
 
   .listCards .detailsItemList .card-footer .botonAgregar, .botonAgregarYellow, .botonAgregarAgotado{
     padding-left: 0px !important;
     padding-right: 0px !important;
     margin: auto;
   }
 
   .detailsItemList{
     width: 100px !important;
   }
 
   .listCards .detailsItemList .card-footer .botonAgregar button, .botonAgregarYellow button, .botonAgregarAgotado button{
     width: 70px !important;
     padding-left: 0px !important;
     padding-right: 0px !important;
     margin-left: 25px !important;
   }
 
   .listCards .detailsItemList .card-footer .cantidad input{
     width: 65px !important;
     margin: 0px !important;
     margin-right: 10px !important;
   }
 }
 
 @media (min-width: 576px) and (max-width:767px){
   .listCards{
     background: #FFF !important;
     width: 550px !important;
     height: 305.500px;
     margin-left: auto !important;
     margin-right: auto !important;
   }
 
   .listCards .detailsItemList{
     width: 180px;
   }
   .listCards .precioCard .precios .descuento{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: auto !important;
    font-size: 18px !important;
  }
 
   .imagenArticulo{
    /* height: 150px !important; */
    margin: auto !important;
   }

   .listCards .detailsItemList .card-footer .botonAgregar button, .botonAgregarYellow button, .botonAgregarAgotado button{
    width: 90px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: auto !important;
  }
 
   .precioCard .precios{
     height: 30px;
     /* display: grid !important; */
     grid-template-columns: 0.5fr 1.5fr !important;
     margin-bottom: 10px;
   }
 }
 
 @media (min-width: 211px) and (max-width:575px){
   .listCards .imagenArticulo{
     margin: auto !important;
     /* height: 115px !important; */
     padding-right: 0px;
     background: transparent !important;
   }
 
   .listCards .precioCard .row{
     width: 180px !important;
   }
 
   .precioCard .precios{
     height: 30px;
     /* display: grid !important; */
     grid-template-columns: 0.5fr 1fr !important;
     padding-left: 0px !important;
   }

   .precioCard .precios .pricePromo{
     padding-left: 0px !important;
     padding-right: 0px !important;
   }

   .precioCard .precios .descuento{
     padding-left: 0px !important;
     padding-right: 0px !important;
     font-size: small !important;
     width: 120px;
     margin: 0;
   }
 
   .precios{
     width: 20px !important;
     display: grid !important;
     grid-template-rows: 1fr 1fr !important;
   }
 
   .precios .precioPromocion{
     max-width: 80px !important;
   }
 
   .listCards .row{
    min-width: 360px;
    /* width: 318px !important; */
   }
 
   .listCards .detailsItemList{
     width: 150px !important;
     padding-left: 2px !important;
     padding-right: 2px ;
     /* height: 300px !important; */
   }
 
   .listCards .detailsItemList .card-body{
    /* height: 210px !important; */
    background: transparent !important;
  }
 
   .listCards .detailsItemList .card-body .card-subtitle{
     width: 150px !important;
     margin-left: 0px !important;
     margin-right: 0px !important;
     padding-top: 0px !important;
     padding-bottom: 0px !important;
     background: transparent !important;
   }
 
   .nameItemCardList .row{
     width: 150px !important;
   }

   .nameItemCardList .nameItemList{
    font-size: smaller !important;
  }

   .listCards .cotizacion{
     width: 150px !important;
   }
 
   .listCards .detailsItemList .card-footer .row{
     display: grid !important;
     grid-template-columns: 0.3fr 0.3fr 0.5fr !important;
     width: 165px !important;
   }
 
   .listCards .detailsItemList .card-footer .botonAgregar, .botonAgregarYellow, .botonAgregarAgotado{
     width: 72px !important;
     padding-left: 0px !important;
     padding-right: 0px !important;
     margin-left: 8px;
   }
 
   .listCards .detailsItemList .card-footer .botonAgregar button, .botonAgregarYellow button, .botonAgregarAgotado button{
     width: 70px !important;
     padding-left: 0px !important;
     padding-right: 0px !important;
     margin-left: 0px !important;
   }
 
   .listCards .detailsItemList .card-footer .cantidad input{
     width: 56px !important;
     margin: 0px !important;
   }
 
   .listCards .detailsItemList .card-footer .icoCarrito img{
     margin: 0px !important;
   }
 }

/* correccion de vista de card con item en PROMOCIÓN */
.imageCard .precios{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.imageCard .precios .descuento{
  padding-left: 0px !important;
  padding-right: 0px !important;
  font-size: 16px !important;
  margin: auto !important;  
}

.imageCard .nameItemCardList .row{
  width: 300px !important;
}

/* precios promocion en itemSlider */
.precioPromocionItemList .descuento{
  padding-left: 0px !important;
  padding-right: 8px !important;
  font-size: 14px !important;
  margin: auto !important;  
  width: 80px;
}

.preciosItemSlider .pricePromoItemSlider{
  padding-right: 0px !important;
  margin-left: 0px !important;
} 

@media (min-width: 211px) and (max-width:575px){
  .precioPromocion{
    width: 150px !important;
    margin-left: 5px !important;
  }

  .preciosItemSlider .pricePromoItemSlider{
    /* background: red !important; */
    width: 150px;
    font-size: 23px !important;
  }
}
.scroll-container{
    display: block !important;
    width: 350px !important;
    height: 200px !important;
    overflow-y: scroll !important;
    scroll-behavior: smooth !important;
  }

  #scroll-container{
    display: block !important;
    width: 350px !important;
    height: 200px !important;
    overflow-y: scroll !important;
    scroll-behavior: smooth !important;
  }
#root {
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Helvetica';
}

.loader {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

select:focus, select.form-control:focus, input.form-control:focus, select.custom-select:focus {
    border-color: rgb(13, 98, 168) !important;
    box-shadow: 0 0px 0px rgb(13, 98, 168) inset, 0 0 8px rgb(13, 98, 168) !important;
    outline: 0 none;
}
/********************** navbar ******************************************/

.header-top, .fixed-top, .navBar-container{
    z-index: 95 !important;
}

.navbar-brand {
    margin-left: 10px;
}

.navbar-logo{
    font-size: 20px!important;
    font-weight: 500;
    padding: 10px;
    cursor: pointer;
}

.input-group-color {
    background-color: rgb(13, 98, 168) !important;
    color: white !important;
}

.nav-item{
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

.input-search-responsive{
    position: fixed;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    right:5%;
    max-width: 90%;
    z-index: 100;
}

.menu-user-responsive{
    position: fixed;
    right: 0%;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    border: 1px solid white;
}

.menu-user-responsive-2{
    position: fixed;
    right: 0%;
    width: 100%;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    border: 1px solid white;
    z-index: 90;
}

/* second navbar*/
.navbar-two{
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.4), 0 1px 1px 0 rgba(0,0,0,0.09);
}

.navbar-two button{
    margin-left: auto !important;
}

/****************++  modal login *******************++*/
#itemDetails {
    z-index: 98!important;
}

.modal .fade {
    z-index: 98!important;
}

.modal-backdrop {
    z-index: 97 !important;
}

.login-image{
    max-height: 50px;
}

.modal-header{
    background-color: rgb(259,259,250);
    height: 40px;
    padding: 5px !important;;
}

.modal-title{
    margin: 0;
    padding: 0;
    text-transform:uppercase;
}

.logpublic {
    background-color:rgb(187, 187, 187);
    opacity: 0.8 !important;
}

.register{
    border-color: transparent !important;
    background-color: transparent !important;
    color: rgb(249, 192, 0) !important;
}

.paginacion{
    margin-top: auto !important;
    margin-right: 2px;
}

.buttonPagination{
    margin: 2px !important;
}

.pag{
    margin-top: 8px !important;
    margin-bottom: 5px !important;
}

.scroll-tip{
    overflow-y: auto !important;
}

.none-scroll{
    overflow-x: hidden;
}

/* ::-webkit-scrollbar {display: none;} */

#scroll-container {
  overflow: hidden;
  max-width: 100%;
  top: 4px;
  position: relative;
  max-height: 35px;
}

.wrap-container {
  max-height: 35px;
  width: 100%; 
  max-width: 100%;
   margin: auto;
  background: #E3E3E3;
  overflow: auto;
  color: #666666;
}

.wrap-container ul {
  list-style: none;
  position: relative;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
}

.wrap-container ul, .wrap-container ul li {
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.wrap-container ul li {
  height: 28px;
  line-height: 28px;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  overflow: hidden;
}

/* .wrap-container ul li:last-of-type { padding-bottom: 50px; } */

/* .wrap-container ul li.actives .item { transform: scale(1.9); } */

.wrap-container ul li .item {
  position: relative;
  -webkit-transition: -webkit-transform 200ms;
  transition: -webkit-transform 200ms;
  transition: transform 200ms;
  transition: transform 200ms, -webkit-transform 200ms;
  display: inline-block;
}

/************************************     video    ****************************************************/
section {
    position: relative;
    background: white;
    height: 80vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
}

section video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

section .container {
    position: relative;
    z-index: 2;
}

section .overlay-wcs {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.2;
    z-index: 1;
}

/**************************** items ********************************************************/
.card-title {
    font-size: 20px;
}

.platform-effect {
    -webkit-transition: all .5s cubic-bezier(1,0,.1,1);
    transition: all .5s cubic-bezier(1,0,.1,1);
}

.platforms {
    min-width: 100%;
    height:100%;
}

.platforms ul{
    margin: 0;
    padding:0;
}

.platforms li{
    text-decoration: none;
    list-style: none;
    text-align: center;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    justify-content: center;
}

.carrito {
    font-family: 'Lato' !important;
    font-size: 15pt !important;
    color: rgb(124, 124, 125) !important;;
}

.ghost:hover {
    background-color: rgba(13, 98, 168,0.5);
}

#itemDescription:disabled {
    background: transparent;
}

.bottonItem{
    font-family: 'Lato Light';
    font-size: 25pt;
    height: auto;
    border-radius: 350px !important;
}

.bottonmodal{
    font-family: 'Lato Light';
    font-size: 25pt;
    border-radius: 350px !important;
    width: 150px;
    height: 42px;
}

.bottongf{
    background: #808080 !important;
}

.bottongc{
    background-color: #dfe0de !important;
}

.colorText{
    color:#666666 !important;
}

::-webkit-input-placeholder {
    color: rgb(235, 227, 227);
}

:-ms-input-placeholder {
    color: rgb(235, 227, 227);
}

::-ms-input-placeholder {
    color: rgb(235, 227, 227);
}

::placeholder {
    color: rgb(235, 227, 227);
}

th{
    background-color: transparent;
}
input[placeholder="Cantidad"]::-webkit-input-placeholder {
    color: #666666;
}
input[placeholder="Cantidad"]:-ms-input-placeholder {
    color: #666666;
}
input[placeholder="Cantidad"]::-ms-input-placeholder {
    color: #666666;
}
input[placeholder="Cantidad"]::placeholder {
    color: #666666;
}

/*EVALUACIÓN DE ESTRELLAS*/
.ec-stars-wrapper {
    /* Espacio entre los inline-block*/
    font-size: 0;
    display: inline-block;
}

.ec-stars-wrapper a {
    text-decoration: none;
    display: inline-block;
    font-size: 32px;
    font-size: 2rem;
    color: #888;
}

/* Color de selector de pdre*/
.ec-stars-wrapper:hover a {
    color: #eabf28;
}

/* Color de selector de hijo*/
.ec-stars-wrapper > a:hover ~ a {
    color: #888;
}
/*-------------------------------*/

#img.zoom {
    width: 400px;
    height: 300px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
}

.transition {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
}

.sombra {
    box-shadow: 0px 3px 16px -1px rgba(0,0,0,0.55);
}

.h1 {
    background-image: url(https://www.gifsanimados.org/data/media/529/fondo-imagen-animada-1192.gif);
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    text-transform: uppercase;
    font-size: 10px;
    line-height: .75;
    margin: 10px 0;
}

/* styling my button */
.white-mode {
    text-decoration: none;
    padding: 7px 10px;
    background-color: #122;
    border-radius: 3px;
    color: #000;
    -webkit-transition: .35s ease-in-out;
    transition: .35s ease-in-out;
    position: absolute;
    left: 15px;
    bottom: 15px;
    font-family: "Montserrat";
}

.white-mode:hover {
    background-color: #FFF;
    color: #122;
}

/* Estilos de el menú de la navbar */
#menu_gral {
    font-family: verdana, sans sherif;
    /* width: 55% ; */
    margin:  auto;
    align-content: auto;
}

.dropbtn {
    background-color: transparent;
    color: rgb(58, 58, 58);
    padding: 4px;
    font-size: 0.8rem;
    border: 0;
}

.dropdown {
    position: absolute;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 1000px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    color: #000;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: inline-block;
    width: 12%;
}

.dropdown-content a:hover {background-color: rgb(198, 160, 12);color: #000;box-shadow: 0px 8px 16px 0px rgba(255, 255, 255, 0.2)}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: rgb(198, 160, 12); color:#fff}
.dropdown-toggle::after {
    color: white !important;
}
/* ------------------------------------------------------------------------------- */

/*Styles Navbar two */
.accordion {
    background-color: rgb(250, 250, 250);
    color: rgb(0, 0, 0);
    padding: 5px;
    width: 100%;
    height: 25%;
    border: none;
    outline: none;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.active, .accordion:hover {
    background-color: #A7A6A5;
}

.panel {
    padding: 0 18px;
    display: none;
    background-color: white;
    overflow: hidden;
}

/*********************************************      tags      ********************************************************/
.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
}
  
.dropdown-submenu:hover>a:after {
    border-left-color: #fff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    border-radius: 6px 0 6px 6px;
}

/*****************************************************************************************************/


.divBonificaciones {
    padding: 0px !important;
}
.menu-title{
    text-align: center;
    padding: 10px !important;
}

.menu-categories {
    position: fixed;
    z-index: 95;
    margin-top: -69px;
    padding-top: 106px;
    background-color: transparent;
    min-height: 100vh;
    width:100%;
    -webkit-transition: all .5s cubic-bezier(.55,0,.1,1);
    transition: all .5s cubic-bezier(.55,0,.1,1);
    -webkit-transform: translate3d(-105%,0,0);
            transform: translate3d(-105%,0,0);
}

.menu-sub-categories {
    box-shadow: 3px 0px 8px 0 rgba(0,0,0,0.2), 3px 0px 8px 0 rgba(0,0,0,0.19);
    min-height: 100vh;
    width:100%;
    filter: alpha(opacity=50); /* internet explorer */
    opacity: 0.8;           /* safari, chrome, opera */
}

.open-menu-categories{
    -webkit-transform: translate3d(0px,0,0);
            transform: translate3d(0px,0,0);
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}


.menu-categories .navbar-nav .nav-link img{
    margin-bottom:5px;
}
.menu-categories .nav-link:hover {
    background-color: #d2ab24;
}

.menu-categories .navbar-nav .nav-item {
    min-width: 100%
}

.menu-categories .navbar-nav .nav-link {
    position: relative;
    white-space: nowrap;
}

@media screen and (min-width:1920px){
    .menu-categories {
        padding-top: 150px !important;
    }

    .menu-sub-categories {
        width:30%;
    }
}

@media (min-width: 1262px) and (max-width: 1919px){
    .menu-categories {
        padding-top: 70px;
    }

    .menu-sub-categories {
        width:30%;
       min-height: 100vh;
    }
}

@media (min-width: 945px) and (max-width: 1261px){
    .menu-categories {
        padding-top: 70px;
    }

    .menu-sub-categories {
        width:40%;
       min-height: 72vh;
    }
}

@media (max-height: 724px){

    .menu-sub-categories {
       min-height: 65vh;
    }
}


@media (min-width: 768px) and (max-width: 944px){
    .menu-categories {
        padding-top: 70px;
    }

    .menu-sub-categories {
        width:45%;
       /* min-height: 72vh; */
    }
}

@media (min-width: 577px ) and (max-width: 767px){
    .menu-categories{
        padding-top: 100px !important;
    }
    .menu-sub-categories {
        width:100%;
        min-height: 1px;
    }
}


@media screen and (max-width:576px){
    .menu-categories{
        margin-top: -55px !important;
        min-height: 1px;
    }
}

@media screen and (max-height:324px){
    .menu-categories{
        margin-top: -55px !important;
    }
    .menu-sub-categories {
        min-height: 1px;
        width:100%;
    }
}

/*---------fijar encabezado de menu categorias en responsive ---------*/
/* .scrollCategories .titleCategories{
    position: sticky !important;
    top: 0;
    z-index: 10;
}
 
.scrollCategories ul{
    overflow-y: scroll !important;
    height: 100vh !important;
} */

/* ------------------------------------------------------------------------- */
 /* PESTAÑA ACTIVA EN REPORTES */
 .navbar-nav > .active > a:focus {
    background-color: #2d75bd !important;
  }
@media only screen and (max-width: 575px) {
    .shopping-cart{
        position: relative;
        font-size: 12px;
        text-align: center;
        cursor: pointer;
    }

    .ico-nav-shearch-two{
        display: none;
    }

    .menu-sub-categories {
        min-height: 1px;
        width:100%;
    }
}

/* tabla Mis pedidos */
.scrolltable thead tr th { 
    position: -webkit-sticky; 
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: rgb(0, 94, 173);
}

.scrolltable table-responsive { 
    height:200px;
    overflow:scroll;
}

@media (min-width: 320px) and (max-width: 767px){
    .tableReports{
        max-height: 400px !important;
        overflow: auto !important;
    }
}

/* ------estilo de tablas */
/* .table-striped{
    box-shadow: 0px 0px 14px -2px rgba(0,0,0,0.75);
} */

.table-striped>tbody>tr:nth-child(odd)>td, 
.table-striped>tbody>tr:nth-child(odd)>th {
 background-color: #fff;
}
.table-striped>tbody>tr:nth-child(even)>td, 
.table-striped>tbody>tr:nth-child(even)>th {
 background-color: #E2E2E2;
}

/* ----------------- ESTO ES PARA QUE LAS FLECHAS DEL CARRUSEL NO SE SOBREPONGAN EN LA NAVBAR -----------------*/
.react-multiple-carousel__arrow--left{
    z-index: 0 !important;
    left: calc(0% + 1px) !important;
}

.react-multiple-carousel__arrow--right{
    z-index: 0 !important;
    right: calc(0% + 1px) !important;
}

/* ESTILO DE LAS CARDS EN ITEMLIST */
#CardItem .card{
    background: white !important;
    border: none !important;
    border-radius: 15px;
}

#CardItem  .card-body {
    font-family: 'Helvetica';
    background: white;
    border-top-style: solid;
    border-top-color: #efefef !important;
  }
  
#CardItem .card-footer{
    background: transparent !important;
    margin-top: auto;
}

.cantidad input{
    border: transparent;
    background: #efefef;
    border-radius: 10px;
}
  
.cantidad input::-webkit-input-placeholder{
    font-style: normal !important;
}
  
.cantidad input:-ms-input-placeholder{
    font-style: normal !important;
}
  
.cantidad input::-ms-input-placeholder{
    font-style: normal !important;
}
  
.cantidad input::placeholder{
    font-style: normal !important;
}
  
.botonAgregar button{
    background: #89c33f;
    border: transparent;
    color: white;
    font-weight: bold;
    border-radius: 10px;
}
  
.botonAgregar button:hover{
    background: #89c33f;
    border: transparent;
    color: white;
    font-weight: bold;
    border-radius: 10px;
}

.botonAgregarYellow button{
    background: #efc964;
    border: transparent;
    color: white;
    font-weight: bold;
    border-radius: 10px;
}
  
.botonAgregarYellow button:hover{
    background: #efc964;
    border: transparent;
    color: white;
    font-weight: bold;
    border-radius: 10px;
}

/* COLOR DE LOS TITULOS DE LAS CATEGORIAS EN ITEMLIST */
.TituloCategoria{
    color: #333333;
    font-size: 25px;
}

.SubCategorias{
    color: #808080;
}
  
.social-btn .btn {
    border: none;
    margin: 10px 3px 0;
    opacity: 1;
}

.social-btn .btn:hover {
    opacity: 0.9;
}

.social-btn .btn-secondary, .social-btn .btn-secondary:active {
    background: #507cc0 !important;
}

.social-btn .btn-info, .social-btn .btn-info:active {
    background: #64ccf1 !important;
}

.social-btn .btn-danger, .social-btn .btn-danger:active {
    background: #df4930 !important;
}

/* Login view */
.text-decoration-underline{
    border-bottom: 3px solid white;
    padding-bottom: 3px;
}

@media screen and (min-width: 1024px) {
    .marq {
        width: 130px;
        margin: auto; 
        display: inline-block;
        overflow: hidden; 
        white-space: nowrap; 
        color: #0078C0 !important;
        font-weight: bold !important;
    }
}

@media (min-width: 768px) and (max-width: 991px){
    .marq {
        width: 100px;
        margin: auto; 
        display: inline-block;
        overflow: hidden; 
        white-space: nowrap; 
    }
}

.orig {
    display: inline-block; 
    -webkit-transform: translatex(0); 
            transform: translatex(0); 
    -webkit-animation: marquesina 10s infinite normal linear 1s; 
            animation: marquesina 10s infinite normal linear 1s; 
}

@-webkit-keyframes marquesina {
    0% {-webkit-transform: translatex(0);transform: translatex(0); }
    50% {-webkit-transform: translatex(-30%);transform: translatex(-30%); }
  }

@keyframes marquesina {
    0% {-webkit-transform: translatex(0);transform: translatex(0); }
    50% {-webkit-transform: translatex(-30%);transform: translatex(-30%); }
  }


.ulLogin {
    list-style: none;
}

/* Modal de verificacion dos pasos */
/* #TwoStepsModal .modal-content .modal-header{
    background-color: #0078c0 !important;
} */

#TwoStepsModal .modal-content .modal-header .modal-title, #TwoStepsModal .modal-content .modal-header button{
    color: white !important;
    font-weight: bolder !important;
}

.inputTwoSteps {
    height: auto;
    width: 40px !important;
    border: solid 1px #ccc;
    box-shadow: 0 0 5px #ccc inset;
    outline: none;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.inputTwoSteps1 {
    height: auto;
    width: 160px !important;
    border: solid 1px #ccc;
    box-shadow: 0 0 5px #ccc inset;
    outline: none;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    font-size: 22px !important;
    font-family: 'Helvetica' !important;
}

.inputTwoSteps:focus {
    border-color: #0078C0;
    box-shadow: 0 0 5px #0078C0 inset;
}

.inputTwoSteps::selection {
    background: transparent;
}

/* margen superior de la vista mi perfil */
@media screen and (min-width: 1024px){
    .margenMyProfile{
        padding-top: 230px !important;
    }
}

@media (min-width: 768px) and (max-width: 1023px){
    .margenMyProfile{
        padding-top: 150px !important;
    }  
}

@media (min-width: 320px) and (max-width: 767px){
    .margenMyProfile{
        padding-top: 180px !important;
    }  
}

/* margen superior de la vista selector */
@media screen and (min-width: 1024px){
    .margenSelector{
        padding-top: 150px !important;
    }
}

@media (min-width: 768px) and (max-width: 1023px){
    .margenSelector{
        padding-top: 90px !important;
    }  
}

@media (min-width: 320px) and (max-width: 767px){
    .margenSelector{
        padding-top: 80px !important;
    }  
}


/* fondo de la tabla de facturas vencidas en reportes */
.overdue .tab-content .tab-pane{
    background: transparent !important;
}
/* --------------------------------------------------- */
/* ------------------------------------------------------ */

/* Hover sobre agregar o agotado en carrito de compras */
.btn.btn-block.botonAgregar{
    background: #89c33f;
}

.btn.btn-block.botonAgregar::before{
    content:"Agregar";
    border: transparent;
    color: white;
    font-weight: bold;
    border-radius: 10px;
}

.btn.btn-block.botonAgregar:hover::before{
    content:"Comprar";
    border: transparent;
    color: white;
    font-weight: bold;
    border-radius: 10px;
}

.botonAgregarAgotado button{
    background: #e27753;
    border: transparent;
    color: white;
    font-weight: bold;
    border-radius: 10px;
}

.btn.btn-block.botonAgregarAgotado{
    background: #e27753;
}

.btn.btn-block.botonAgregarAgotado::before{
    content:"Agregar";
    border: transparent;
    color: white;
    font-weight: bold;
    border-radius: 10px;
}

.btn.btn-block.botonAgregarAgotado:hover::before {
    content:"Agotado";
    border: transparent;
    color: white;
    font-weight: bold;
    border-radius: 10px;
}

.btn.btn-block.botonAgregarYellow{
    background:#efc964;
}
  
.btn.btn-block.botonAgregarYellow::before{
    content:"Agregar";
    border: transparent;
    color: white;
    font-weight: bold;
    border-radius: 10px;
}

.btn.btn-block.botonAgregarYellow:hover::before{
    content:"Solicitar";
    border: transparent;
    color: white;
    font-weight: bold;
    border-radius: 10px;
}

/* imagenes de formas de pago en el carrito */
.formasPagoImages img{
    width: 45px;
}

/* seccion de cupones en shoppincartView */
.seccionCupon .input-group input{
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    max-width: 50% !important;
}

.seccionCupon .input-group button{
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

/* campo comentario en selectAddress */
.coment textarea::-webkit-input-placeholder{
    color: #0076B8 !important;
}
.coment textarea:-ms-input-placeholder{
    color: #0076B8 !important;
}
.coment textarea::-ms-input-placeholder{
    color: #0076B8 !important;
}
.coment textarea::placeholder{
    color: #0076B8 !important;
}

/* margen de vista contacto */
@media screen and (min-width: 1200px){
    .Viewcontact {
        padding-top: 200px;   
    } 
}

@media screen and (max-width: 450px){
    .Viewcontact .correo{
        font-size: 1rem;
    }
}

/* margen superior RedAliado */
.margenSuperiorRedAliado{
    padding-bottom: 25px;
}

/* estilos para progressBar del estado de documento en reportes*/
.timeline{
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0px !important;
}

.timeline .li{
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
  margin-bottom: auto !important;
}

.timeline .statusAceptado, .statusPreparando, .statusEmpaquetando, .statusFacturado, .statusCancelado{
  padding: 0px 40px;
  justify-content: center;
  border-top: 2px solid #D6DCE0;
  position: relative;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}  

.timeline h4{
    font-weight: 600;
}

.statusAceptado::before, .statusPreparando::before, .statusEmpaquetando::before, .statusFacturado::before, .statusCancelado::before{
    content: '';
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 25px;
    border: 1px solid #ddd;
    position: absolute;
    top: -15px;
    left: 42%;
    -webkit-transition: all 200ms ease-in;
    transition: all 200ms ease-in; 
}

/* si exite el estado de documento se pinta la bolita de azul */
.timeline .li.complete .statusAceptado{
    border-top: 2px solid #0078C0;
}

.timeline .li.complete .statusAceptado:before{
    border: none;
    -webkit-transition: all 200ms ease-in;
    transition: all 200ms ease-in; 
    content: "\f058";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: black;
    position: absolute;
}

.timeline .li.complete .statusPreparando{
    border-top: 2px solid #0078C0;
}

.timeline .li.complete .statusPreparando:before{
    border: none;
    -webkit-transition: all 200ms ease-in;
    transition: all 200ms ease-in; 
    content: "\f013";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: black;
    position: absolute;
}

.timeline h4{
    color: #0078C0;
}

.timeline .li.complete .statusEmpaquetando{
    border-top: 2px solid #0078C0;
}

.timeline .li.complete .statusEmpaquetando:before{
    border: none;
    -webkit-transition: all 200ms ease-in;
    transition: all 200ms ease-in; 
    content: "\f06b";
    font-family: "Font Awesome 5 Free";
    color: black;
    font-weight: 900;
    position: absolute;
}

.timeline .li.complete .statusFacturado{
    border-top: 2px solid #0078C0;
}

.timeline .li.complete .statusFacturado:before{
    border: none;
    -webkit-transition: all 200ms ease-in;
    transition: all 200ms ease-in; 
    content: "\f02f";
    font-family: "Font Awesome 5 Free";
    color: black;
    font-weight: 900;
    position: absolute;
}

.timeline .li.complete .statusCancelado{
    border-top: 2px solid #0078C0;
}

.timeline .li.complete .statusCancelado:before{
    border: none;
    -webkit-transition: all 200ms ease-in;
    transition: all 200ms ease-in; 
    content: "\f057";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: black;
    position: absolute;
}

/* si no se encuentra el estado del doc. se pinta la bolita de gris */
.timeline .li .incomplete .status{
  border-top: 2px solid black !important;
  color: black !important;
}

.timeline .li .incomplete .status:before{
    background-color: black !important;
    color: black !important;
    border: none;
    -webkit-transition: all 200ms ease-in;
    transition: all 200ms ease-in; 
}

.timeline .li .incomplete .timestamp{
    background: black !important;
}

@media (min-width: 992px) and (max-width:1199px){
    .timeline{
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0px !important;
    }
    
    .timeline .li{
      -webkit-transition: all 200ms ease-in;
      transition: all 200ms ease-in;
      margin-bottom: auto !important;
    }
    
    .timeline .statusAceptado, .statusPreparando, .statusEmpaquetando, .statusFacturado, .statusCancelado{
      padding: 0px 15px;
      justify-content: center;
      border-top: 2px solid #D6DCE0;
      position: relative;
      -webkit-transition: all 50ms ease-in;
      transition: all 50ms ease-in;
      font-size: 18px;
    }
    
    .statusAceptado::before, .statusPreparando::before, .statusEmpaquetando::before, .statusFacturado::before, .statusCancelado::before{
        content: '';
        width: 25px;
        height: 25px;
        background-color: white;
        border-radius: 25px;
        border: 1px solid #ddd;
        position: absolute;
        top: -15px;
        left: 42%;
        -webkit-transition: all 200ms ease-in;
        transition: all 200ms ease-in; 
    }
    
    /* si exite el estado de documento se pinta la bolita de azul */
    .timeline .li.complete .statusAceptado{
        border-top: 2px solid #0078C0;
    }
    
    .timeline .li.complete .statusAceptado:before{
        border: none;
        -webkit-transition: all 200ms ease-in;
        transition: all 200ms ease-in; 
        content: "\f058";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: black;
        position: absolute;
    }
    
    .timeline .li.complete .statusPreparando{
        border-top: 2px solid #0078C0;
    }
    
    .timeline .li.complete .statusPreparando:before{
        border: none;
        -webkit-transition: all 200ms ease-in;
        transition: all 200ms ease-in; 
        content: "\f013";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: black;
        position: absolute;
    }
    
    .timeline .li.complete .statusEmpaquetando{
        border-top: 2px solid #0078C0;
    }
    
    .timeline .li.complete .statusEmpaquetando:before{
        border: none;
        -webkit-transition: all 200ms ease-in;
        transition: all 200ms ease-in; 
        content: "\f06b";
        font-family: "Font Awesome 5 Free";
        color: black;
        font-weight: 900;
        position: absolute;
    }
    
    .timeline .li.complete .statusFacturado{
        border-top: 2px solid #0078C0;
    }
    
    .timeline .li.complete .statusFacturado:before{
        border: none;
        -webkit-transition: all 200ms ease-in;
        transition: all 200ms ease-in; 
        content: "\f02f";
        font-family: "Font Awesome 5 Free";
        color: black;
        font-weight: 900;
        position: absolute;
    }
    
    .timeline .li.complete .statusCancelado{
        border-top: 2px solid #0078C0;
    }
    
    .timeline .li.complete .statusCancelado:before{
        border: none;
        -webkit-transition: all 200ms ease-in;
        transition: all 200ms ease-in; 
        content: "\f057";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: black;
        position: absolute;
    }
    
    /* si no se encuentra el estado del doc. se pinta la bolita de gris */
    .timeline .li .incomplete .status{
      border-top: 2px solid black !important;
      color: black !important;
    }
    
    .timeline .li .incomplete .status:before{
        background-color: black !important;
        color: black !important;
        border: none;
        -webkit-transition: all 200ms ease-in;
        transition: all 200ms ease-in; 
    }
    
    .timeline .li .incomplete .timestamp{
        background: black !important;
    }
}

@media (min-device-width: 320px) and (max-device-width: 991px){
    .timeline{
        list-style-type: none;
        display: block;
    }

    .timeline .li{
        -webkit-transition: all 200ms ease-in;
        transition: all 200ms ease-in;
        display: flex;
        width: inherit;
    }

    .timeline .statusAceptado, .statusPreparando, .statusEmpaquetando, .statusFacturado, .statusCancelado{
        padding: 0px 40px;
        justify-content: center;
        border-top: 2px solid #D6DCE0;
        position: relative;
        -webkit-transition: all 200ms ease-in;
        transition: all 200ms ease-in;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .timeline .status:before{
        left: -8%;
        top: 30%;
        -webkit-transition: all 200ms ease-in;
        transition: all 200ms ease-in;
    }
}
.pagination{
    display:inline-block;
    padding-left:0;
    margin:20px 0;
    border-radius:4px
}
.pagination>li{
    display:inline
}
.pagination>li>a,
.pagination>li>span{
    position:relative;
    float:left;
    padding:6px 12px;
    margin-left:-1px;
    line-height:1.42857143;
    color:#337ab7;
    text-decoration:none;
    background-color:#fff;
    border:1px solid #ddd
}
.pagination>li:first-child>a,
.pagination>li:first-child>span{
    margin-left:0;
    border-top-left-radius:4px;
    border-bottom-left-radius:4px
}
.pagination>li:last-child>a,
.pagination>li:last-child>span{
    border-top-right-radius:4px;
    border-bottom-right-radius:4px
}
.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover{
    z-index:3;
    color:#23527c;
    background-color:#eee;
    border-color:#ddd
}
.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover{
    z-index:2;
    color:#fff;
    cursor:default;
    background-color:#337ab7;
    border-color:#337ab7
}
.pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover{
    color:#777;
    cursor:not-allowed;
    background-color:#fff;
    border-color:#ddd
}
.pagination-lg>li>a,
.pagination-lg>li>span{
    padding:10px 16px;
    font-size:18px;
    line-height:1.3333333
}
.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span{
    border-top-left-radius:6px;
    border-bottom-left-radius:6px
}
.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span{
    border-top-right-radius:6px;
    border-bottom-right-radius:6px
}
.pagination-sm>li>a,
.pagination-sm>li>span{
    padding:5px 10px;
    font-size:12px;
    line-height:1.5
}
.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span{
    border-top-left-radius:3px;
    border-bottom-left-radius:3px
}
.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span{
    border-top-right-radius:3px;
    border-bottom-right-radius:3px
}


.BreakWord{
    display: inline-block;
    word-break: break-word;
}

@media only screen and (max-width: 825px){
    .breadcrumb{
        font-size: 20px !important;
    }
}

.fondo{
    background: -webkit-linear-gradient(45deg, rgb(0,94,173) 0%, rgb(0,94,173) 100%);
    height: 113.841px; 
}

.fondo input::-webkit-input-placeholder{
    color: rgb(41, 111, 187);
    font-style: normal;
}

.fondo input:-ms-input-placeholder{
    color: rgb(41, 111, 187);
    font-style: normal;
}

.fondo input::-ms-input-placeholder{
    color: rgb(41, 111, 187);
    font-style: normal;
}

.fondo input::placeholder{
    color: rgb(41, 111, 187);
    font-style: normal;
}

.form-control input:focus{
    background: -webkit-linear-gradient(45deg, rgba(0,118,187,1) 0%, rgba(41,91,144,1) 100%);
    color: white;
    border:1px solid white !important;
    outline: none !important;
}

.fondo button:hover{
    color: white !important;
}

.fondo button i{
    font-size: 1.3rem !important;
}


.SeccionBoletin span{
    margin-top: auto !important;
    margin-bottom: auto !important;
}

@media (min-width: 1920px) and (max-width: 3500px){
    .fondo .Suscripcion{
        width: 696px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .Suscripcion input{
        background-color: white !important;
        border-color: white;
        width: 200px !important;
        border-right: transparent !important;
        margin-left: 40px !important;
        border-bottom-left-radius: 10px !important;
        border-top-left-radius: 10px !important;
    }
    
    .fondo button{
        color: white;
        border: solid 1px white !important;
        font-size: 1rem !important;
        border-left: transparent !important;
        border-bottom-right-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }
}
  
  @media (min-width: 1200px) and (max-width:1919px){
    .fondo .Suscripcion{
        width: 696px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .Suscripcion input{
        background-color: white;
        border-color: white;
        width: 200px !important;
        border-right: transparent !important;
        margin-left: 40px !important;
        border-bottom-left-radius: 10px !important;
        border-top-left-radius: 10px !important;
    }
    
    .fondo button{
        color: white;
        border: solid 1px white !important;
        font-size: 1rem !important;
        border-left: transparent !important;
        border-bottom-right-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }
  }
  
  @media (min-width: 992px) and (max-width:1199px){
    .fondo .Suscripcion{
        width: 910px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .Suscripcion input{
        background-color: white;
        border-color: white;
        width: 200px !important;
        border-right: transparent !important;
        margin-left: 40px !important;
        border-bottom-left-radius: 10px !important;
        border-top-left-radius: 10px !important;
    }
    
    .fondo button{
        color: white;
        border: solid 1px white !important;
        font-size: 1rem !important;
        border-left: transparent !important;
        border-bottom-right-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }
  }
  
  @media (min-width: 768px) and (max-width:991px){
    .fondo .Suscripcion{
        margin-left: auto;
        margin-right: auto;
    }
    
    .Suscripcion input{
        background-color: white;
        border-color: white;
        width: 200px !important;
        border-right: transparent !important;
        margin-left: 40px !important;
        border-bottom-left-radius: 10px !important;
        border-top-left-radius: 10px !important;
    }
    
    .fondo button{
        color: white;
        border: solid 1px white !important;
        font-size: 1rem !important;
        border-left: transparent !important;
        border-bottom-right-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }
  }
  
  @media (min-width: 576px) and (max-width:767px){
    .fondo .Suscripcion{
        margin-left: auto;
        margin-right: auto;
    }
    
    .Suscripcion input{
        background-color: white;
        border-color: white;
        width: 80px !important;
        border-right: transparent !important;
        margin-left: 40px !important;
        border-bottom-left-radius: 10px !important;
        border-top-left-radius: 10px !important;
    }
    
    .fondo button{
        color: white;
        border: solid 1px white !important;
        font-size: 1rem !important;
        border-left: transparent !important;
        border-bottom-right-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }
  }
  
  @media (min-width: 211px) and (max-width:575px){
    .Suscripcion{
        max-width: 290px;
    }
    
    .Suscripcion input{
        background-color: white;
        border-color: white;
        width: 40px !important;
        border-right: transparent !important;
        border-bottom-left-radius: 10px !important;
        border-top-left-radius: 10px !important;
        font-size:0.8rem;
    }
    
    .fondo button{
        color: white;
        border: solid 1px white !important;
        font-size: 0.8rem !important;
        border-left: transparent !important;
        border-bottom-right-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }

    .fondo button i{
        color: white;
        font-size: 0.8rem !important;
    }
  }

@media screen and (max-width: 700px){
  .botonePrincipales{
    font-size: 10px;
  }
}

.contenedor2{
    width:14 px;
    height:150 rem;
    background-color:red;
    margin-top: 10px;
    /* border: 1px solid black; */
      overflow:hidden;
      margin-bottom: 2rem;
  }

  .Img{
      margin-top:1rem;
      width: 353px;
      height: 353px;
      border: 5px solid black;
      margin-left: 15px;
      margin-bottom:20px;
  }

  .Imagem{
    margin-top:1rem;
    width: 353px;
    height: 353px;
    margin-left: 15px;
    margin-bottom:20px;
    float: left;
}

.contenedor3{
    width: 1845px;
    height: 345px;
    image-resolution: 72 dpi;
    margin-top: 2 rem;
    border: 5px solid black;
    margin-left: 20px;
    margin-bottom: 5px;
}
.h4{
    position: relative;
    top:270px;
    text-align: center;
}
@media (max-width: 767px) {
    .hidden-mobile {
      display: none;
    }
  }

/* MENU AZUL QUE SE ENCUENTRA DEBAJO DEL BANNER */

.categorias .nav{
  background: rgb(0, 94, 0173);
}

.categorias ul li a{
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.categorias img{
  max-width: 11rem !important;
}

@media (min-width: 1920px) and (max-width: 3500px){
  .categorias{
    height: 62.5px;
    width: 1362px;
    margin-left: auto;
    margin-right: auto;
  }

  .categorias img{
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media (min-width: 1200px) and (max-width:1919px){
  .categorias{
    height: 62.5px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .categorias img{
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media (min-width: 992px) and (max-width:1199px){
  .categorias{
    height: 62.5px;
    margin-left: auto;
    margin-right: auto;
  }

  .categorias img{
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media (min-width: 768px) and (max-width:991px){
  .categorias{
    height: 62.5px;
    margin-left: auto;
    margin-right: auto;
  }

  .categorias img{
    padding-top: auto;
    padding-bottom: auto;
  }
}

/* SLIDER DE LA SECCIÓN DE REFACCIONES */
@media (min-width: 1920px) and (max-width: 3500px){
  .titulodeSeccionRefacciones{
    font-size: 36px;
    color: #666666;
    margin-left: 145px;
  }
  .refacciones{
    width: 1330px !important;
    height: 350px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) and (max-width:1919px){
  .titulodeSeccionRefacciones{
    font-size: 36px;
    color: #666666;
    margin-left: 73px;
  }
  .refacciones{
    width: 1100px !important;
    height: 350px !important;
    margin-left: auto;
    margin-right: auto;

  }
}

@media (min-width: 992px) and (max-width:1199px){
  .titulodeSeccionRefacciones{
    font-size: 36px;
    color: #666666;
  }
  .refacciones{
    width: 910px !important;
    height: 266px !important;
    margin-left: auto;
    margin-right: auto;

  }
}

@media (min-width: 768px) and (max-width:991px){
  .titulodeSeccionRefacciones{
    font-size: 36px;
    color: #666666;
  }
  .refacciones{
    height: 266px !important;
    width: 700px !important;
    margin-left: auto;
    margin-right: auto;

  }
}

@media (min-width: 576px) and (max-width:767px){
  .titulodeSeccionRefacciones{
    font-size: 36px;
    color: #666666;
    text-align: center !important;
  }
  .refacciones{
    height: 266px !important;
    width: 500px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 211px) and (max-width:575px){
  .titulodeSeccionRefacciones{
    font-size: 36px;
    color: #666666;
    text-align: center !important;
  }

  .refacciones{
    height: 266px !important;
    width: 310px !important;
    margin-left: auto;
    margin-right: auto;
  }

  .refacciones .react-multi-carousel-item{
    /* margin-right: 3px !important;
    margin-left: 3px !important; */
    margin-right: 0px;
    margin-left: 0px ;
  }
}

/* SECCION PROMORED */
@media (min-width: 1920px) and (max-width: 3500px){
  .titulodeSeccionPromoRed{
    font-size: 36px;
    color: #666666;
    margin-left: 145px;
  }
}

@media (min-width: 1200px) and (max-width:1919px){
  .titulodeSeccionPromoRed{
    font-size: 36px;
    color: #666666;
    margin-left: 145px;
  }
}

@media (min-width: 992px) and (max-width:1199px){
  .titulodeSeccionPromoRed{
    padding-top: 80px;
    font-size: 36px;
    color: #666666;
  }
}

@media (min-width: 768px) and (max-width:991px){
  .titulodeSeccionPromoRed{
    padding-top: 80px;
    font-size: 36px;
    color: #666666;
  }
}

@media (min-width: 576px) and (max-width:767px){
  .titulodeSeccionPromoRed{
    padding-top: 80px;
    font-size: 36px;
    color: #666666;
    text-align: center !important;
  }
}

@media (min-width: 211px) and (max-width:575px){
  .titulodeSeccionPromoRed{
    padding-top: 80px !important;
    font-size: 36px;
    color: #666666;
    text-align: center !important;
  }
}

/* SECCION DESCUBRE */
@media (min-width: 1920px) and (max-width: 3500px){
  .titulodeSeccionDescubre{
    font-size: 36px;
    color: #666666;
    margin-left: 145px;
  }

  .ConoceTarjeta{
    width: 813.398px;
    height: 348.242;
  }

  .cardsDescubre{
    padding-left: 10% !important;
    padding-right: 10% !important;
  }

  .RefigeracionComercialIndustrial{
    width: 813.398px;
    height: 348.242;
  }
}

@media (min-width: 1200px) and (max-width:1919px){
  .titulodeSeccionDescubre{
    font-size: 36px;
    color: #666666;
    margin-left: 73px;
  }
}

@media (min-width: 992px) and (max-width:1199px){
  .titulodeSeccionDescubre{
    font-size: 36px;
    color: #666666;
  }

  .ConoceTarjeta{
    width: 813.398px;
    height: 348.242;
  }

  .RefigeracionComercialIndustrial{
    width: 813.398px;
    height: 348.242;
  }
}

@media (min-width: 768px) and (max-width:991px){
  .titulodeSeccionDescubre{
    font-size: 36px;
    color: #666666;
  }

  .ConoceTarjeta .btn{
    font-size: 0.5rem !important;
  }

  .RefigeracionComercialIndustrial .btn{
    font-size: 0.5rem !important;
  }
}

@media (min-width: 576px) and (max-width:767px){
  .titulodeSeccionDescubre{
    font-size: 36px;
    color: #666666;
    text-align: center !important;
  }

  .ConoceTarjeta .btn{
    font-size: 1rem !important;
  }

  .RefigeracionComercialIndustrial .btn{
    font-size: 1rem !important;
  }

  .cardsDescubre{
    margin-left: 0px !important;
  }
}

@media (min-width: 211px) and (max-width:575px){
  .titulodeSeccionDescubre{
    font-size: 36px;
    color: #666666;
    text-align: center !important;
  }

  .ConoceTarjeta .btn{
    font-size: 0.5rem !important;
  }

  .RefigeracionComercialIndustrial .btn{
    font-size: 0.5rem !important;
  }
  .cardsDescubre{
    margin-left: 0px !important;
  }
}

/* SECCION NUEVOS PRODUCTOS */
@media (min-width: 1920px) and (max-width: 3500px){
  .titulodeSeccionNuevosProductos{
    font-size: 36px;
    color: #666666;
    margin-left: 145px;
  }
}

@media (min-width: 1200px) and (max-width:1919px){
  .titulodeSeccionNuevosProductos{
    font-size: 36px;
    color: #666666;
    margin-left: 73px;
  }
}

@media (min-width: 992px) and (max-width:1199px){
  .titulodeSeccionNuevosProductos{
    font-size: 36px;
    color: #666666;
  }
}

@media (min-width: 768px) and (max-width:991px){
  .titulodeSeccionNuevosProductos{
    font-size: 36px;
    color: #666666;
  }
}

@media (min-width: 576px) and (max-width:767px){
  .titulodeSeccionNuevosProductos{
    font-size: 36px;
    color: #666666;
    text-align: center !important;
  }
}

@media (min-width: 211px) and (max-width:575px){
  .titulodeSeccionNuevosProductos{
    font-size: 36px;
    color: #666666;
    text-align: center !important;
  }
}

/* SECCIÓN DE ENVÍOS */
.envios{
  background-color: white;
  margin-left: auto !important;
  margin-right: auto !important;
}

.envios a{
  color: #007bff !important;
}

.envios span{
  color: #007bff !important;
}

.envios p{
  font-size: 1rem;
}

.marcas img{
  width: 70%;
}
@media (min-width: 1920px) and (max-width: 3500px){
  .enviosRepublica {
    border-left:solid 1.2px rgb(199, 199, 199);
  }
  
  .comprasSeguras {
    border-left:solid 1.2px rgb(199, 199, 199);
  }

  .envios{
    height: 353.33px;
  }

  .envios .pagosTarjeta{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .envios .enviosRepublica{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .envios .comprasSeguras{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 1200px) and (max-width:1919px){
  .enviosRepublica {
    border-left:solid 1.2px rgb(199, 199, 199);
  }
  
  .comprasSeguras {
    border-left:solid 1.2px rgb(199, 199, 199);
  }

  .envios{
    height: 353.33px;
  }

  .envios .pagosTarjeta{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .envios .enviosRepublica{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .envios .comprasSeguras{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 992px) and (max-width:1199px){
  .enviosRepublica {
    border-left:solid 1.2px rgb(199, 199, 199);
  }
  
  .comprasSeguras {
    border-left:solid 1.2px rgb(199, 199, 199);
  }

  .envios{
    height: 353.33px;
  }

  .envios .pagosTarjeta{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .envios .enviosRepublica{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .envios .comprasSeguras{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 768px) and (max-width:991px){
  .enviosRepublica {
    border-left:solid 1.2px rgb(199, 199, 199);
  }
  
  .comprasSeguras {
    border-left:solid 1.2px rgb(199, 199, 199);
  }

  .envios{
    height: 353.33px;
  }

  .envios .pagosTarjeta{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .envios .enviosRepublica{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .envios .comprasSeguras{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

/* SECCIÓN DE MARCAS */
@media (min-width: 1920px) and (max-width: 3500px){
  .marcas{
    height: 306.4px;
  }
}

@media (min-width: 1200px) and (max-width:1919px){
  .marcas{
    height: 306.4px;
  }
}

@media (min-width: 992px) and (max-width:1199px){
  .marcas{
    height: 234.4px;
  }
}

@media (min-width: 768px) and (max-width:991px){
  .marcas{
    height: 160.4px;
  }
}

@media (min-width: 576px) and (max-width:767px){
  .marcas{
    height: 88.4px;
  }
}

@media (min-width: 211px) and (max-width:575px){
  .marcas{
    height: 88.4px;
  }
}

/* imagenes del carousel de marcas */
@media (min-width: 1920px) and (max-width: 3500px){
  .carouselDash{
    padding-top: 115px;
  }
}

@media (min-width: 1200px) and (max-width:1919px){
  .carouselDash{
    padding-top: 83px;
  }
}

@media (min-width: 992px) and (max-width:1199px){
  .carouselDash{
    padding-top: 46px;
  }
}

@media (min-width: 768px) and (max-width:991px){
  .carouselDash{
    padding-top: 15px;
  }
}

@media (min-width: 576px) and (max-width:767px){
  .carouselDash{
    padding-top: 25px;
  }
}

@media (min-width: 211px) and (max-width:575px){
  .carouselDash{
    padding-top: 15px;
  }
}

/* imagenes del carousel de marcas */
.carouselMarcas img{
  max-width: 8rem !important;
}

.login input::-webkit-input-placeholder{
    color: hsl(210, 14%, 83%) !important;
}

.login input:-ms-input-placeholder{
    color: hsl(210, 14%, 83%) !important;
}

.login input::-ms-input-placeholder{
    color: hsl(210, 14%, 83%) !important;
}

.login input::placeholder{
    color: hsl(210, 14%, 83%) !important;
}

.login input{
    border-color: #ced4da !important;
}

.contenidoLogin .tab-content .tab-pane{
    background: transparent !important;
}

.login .card{
    border-radius: 25px !important;
}

.titulo{
    padding-left: 0px !important;
}

/* Inicio ajustes del responsivo pestaña registro */
.nav-link.active{
    border-bottom: solid 4px #005ead !important;
}


.div-fCard-body{
    height: auto;
}

.div-content-body{
    margin: 10px 0 16px 0;
}
/* Fin ajustes del responsivo pestaña registro */

@media (min-width: 1920px) and (max-width: 3500px){
    .logosLogin{
       background:  #efefef ;
       height: 200px;
       align-items: center !important;
       justify-content: center !important;
    }

    .logosLogin .logoEmpresa img{
        width: 400px !important;
        height: 200px !important;
    }

    .logosLogin .segundaImg img{
        width: 210.89px !important;
        height: 130.637px !important;
    }

    .logosLogin nav{
        padding-top: 45px !important;
    }

    .contenidoLogin{
        height: 715.643px;
        font-family: "Helvetica" !important;
        background: -webkit-linear-gradient(45deg, rgba(11, 91, 160,1) 0%, rgba(0, 91, 160,1) 100%); 
    }

    .borderBotton{
        height: 72.6px !important;
        background:  #efefef !important;
    }

    .titulo{
        font-size: 30pt;
    }

    .subtitulo{
        font-size: 20pt;
    }

    .descripcion ul li{
        font-size: 17pt;
    }
}
  
@media (min-width: 1200px) and (max-width:1919px){
    .logosLogin{
        background:  #efefef ;
        height: 150px;
        align-items: center !important;
        justify-content: center !important;
     }
 
     .logosLogin .logoEmpresa img{
        width: 400px !important;
        height: 200px !important;
    }

    .logosLogin .segundaImg img{
        width: 205.89px !important;
        height: 130.637px !important;
    }
 
     .logosLogin nav{
         padding-top: 35px !important;
     }
 
     .contenidoLogin{
         height: 795.643px;
         font-family: "Helvetica" !important;
         background: -webkit-linear-gradient(45deg, rgba(11, 91, 160,1) 0%, rgba(0, 91, 160,1)100%); 
        }
 
     .borderBotton{
         height: 65.4px !important;
         background:  #efefef !important;
     }
 
     .titulo{
         font-size: 30pt;
     }
 
     .subtitulo{
         font-size: 20pt;
     }
 
     .descripcion ul li{
         font-size: 17pt;
     }
}

@media (min-width: 992px) and (max-width:1199px){
    .logosLogin{
        background:  #efefef ;
        height: 150px;
        align-items: center !important;
        justify-content: center !important;
     }
 
     .logosLogin .logoEmpresa img{
        width: 230px !important;
        height: 80px !important;
    }

    .logosLogin .segundaImg img{
        width: 200px !important;
        height: 85px !important;
    }
 
     .logosLogin nav{
         padding-top: 35px !important;
     }
 
     .contenidoLogin{
         height: 795.643px;
         font-family: "Helvetica" !important;
         background: -webkit-linear-gradient(45deg, rgba(11, 91, 160,1) 0%, rgba(0, 91, 160,1)100%); 
        }
 
     .borderBotton{
         height: 65.4px !important;
         background:  #efefef !important;
     }
 
     .titulo{
         font-size: 30pt;
     }
 
     .subtitulo{
         font-size: 20pt;
     }
 
     .descripcion ul li{
         font-size: 17pt;
     }
}

@media (min-width: 768px) and (max-width:991px){
    .logosLogin{
        background:  #efefef ;
        height: 150px;
        align-items: center !important;
        justify-content: center !important;
     }
 
     .logosLogin .logoEmpresa img{
        width: 220px !important;
        height: 80px !important;
    }

    .logosLogin .segundaImg img{
        width: 200px !important;
        height: 85px !important;
    }
 
     .logosLogin nav{
         padding-top: 35px !important;
     }
 
     .contenidoLogin{
        height: 1000px;
        font-family: "Helvetica" !important;
        background: -webkit-linear-gradient(45deg, rgba(11, 91, 160,1) 0%, rgba(0, 91, 160,1) 100%); 
    }
 
     .borderBotton{
         height: 65.4px !important;
         background:  #efefef !important;
     }
 
     .titulo{
         font-size: 25pt;
     }
 
     .subtitulo{
         font-size: 17pt;
     }
 
     .descripcion ul li{
         font-size: 14pt;
     }
}

@media (min-width: 576px) and (max-width:767px){


    .logosLogin{
        background:  #efefef ;
        height: 150px;
        align-items: center !important;
        justify-content: center !important;
     }
 
     .logosLogin .logoEmpresa img{
        width: 183px !important;
        height: 77px !important;
    }

    .logosLogin .segundaImg img{
        width: 170px !important;
        height: 75px !important;
    }
 
     .logosLogin nav{
         padding-top: 35px !important;
     }
 
     .contenidoLogin{
         height: 1000px;
         font-family: "Helvetica" !important;
         background: -webkit-linear-gradient(45deg, rgba(11, 91, 160,1) 0%, rgba(0, 91, 160,1) 100%); 
     }

     .contenidoLogin .login{
         padding-left: 30px !important;
         padding-left: 15px !important;
     }
 
     .borderBotton{
         height: 65.4px !important;
         background:  #efefef !important;
     }
 
     .titulo{
         font-size: 25pt;
     }
 
     .subtitulo{
         font-size: 17pt;
         margin-left: 20px;
     }
 
     .descripcion ul li{
         font-size: 14pt;
     }
}

@media (min-width: 392px) and (max-width:575px){
    .login .card{
        margin-left: 3px !important;
        margin-right: 3px !important;
    }

    .logosLogin{
        background:  #efefef ;
        height: 230px;
        align-items: center !important;
        justify-content: center !important;
     }
 
     .logosLogin .logoEmpresa img{
        width: 230px !important;
        height: 80px !important;
    }

    .logosLogin .segundaImg img{
        width: 200px !important;
        height: 85px !important;
    }
 
     .logosLogin nav{
         padding-top: 35px !important;
     }
 
     .contenidoLogin{
         max-height: 1200px !important;
         height: 1040px;
         font-family: "Helvetica" !important;
         background: -webkit-linear-gradient(45deg, rgba(11, 91, 160,1) 0%, rgba(0, 91, 160,1)100%); 
     }
 
     .borderBotton{
         height: 65.4px !important;
         background:  #efefef !important;
         top: 10px;
     }
 
     .titulo{
         font-size: 25pt;
     }
 
     .subtitulo{
         font-size: 17pt;
         margin-left: 20px;
     }
 
     .descripcion ul li{
         font-size: 14pt;
     }
}

@media (min-width: 211px) and (max-width:391px){
     .contenidoLogin{
         max-height: 1200px !important;
         height: 1080px;
         font-family: "Helvetica" !important;
         background: -webkit-linear-gradient(45deg, rgba(11, 91, 160,1) 0%, rgba(0, 91, 160,1)100%); 
     }

         /* Fin Contenedor */
    .login .card{
        margin-left: 3px !important;
        margin-right: 3px !important;
    }

    .logosLogin{
        background:  #efefef ;
        height: 230px;
        align-items: center !important;
        justify-content: center !important;
     }
 
     .logosLogin .logoEmpresa img{
        width: 230px !important;
        height: 80px !important;
    }

    .logosLogin .segundaImg img{
        width: 200px !important;
        height: 85px !important;
    }
 
     .logosLogin nav{
         padding-top: 35px !important;
     }

     .borderBotton{
         height: 65.4px !important;
         background:  #efefef !important;
         top: 10px;
     }
 
     .titulo{
         font-size: 25pt;
     }
 
     .subtitulo{
         font-size: 17pt;
         margin-left: 20px;
     }
 
     .descripcion ul li{
         font-size: 14pt;
     }
}

.bannerRedCompensas img{
  width: 100%;
  padding-left: auto !important;
  padding-right: auto !important;
}

.MenuEstadoDeCuenta .list-group{
  padding-top: 100px !important;
}

.list-group-item{
  background: #F2F2F2 !important;
  color: #4d4d4d !important;
}

.tab-content .tab-pane{
  background: #EFEFEF !important;
}

.DetallesEstadoDeCuenta{
  border: solid 2px #AFAFAF;
  border-radius: 10px !important;
}

.list-group-item.list-group-item-action-1.active{
  z-index: 2 !important;
  background-color: #EFEFEF !important;
  border-color: transparent !important;
  text-decoration: none !important;
  color: #0078c0 !important;
  font-weight: bolder !important;
}

.list-group-item.list-group-item-action-1{
  width: 100% !important;
  color:#4d4d4d !important;
  text-align: inherit !important;
  text-decoration: none !important;
  font-weight: bold !important;
  background: white !important;
}

#list-estado, #list-vitrina, #list-politicas, #list-aviso, #list-promos{
  padding-top: 60px !important;
}

.page-item.active .page-link{
  background: #0078c0 !important;
  border-color: #0078c0 !important;
}

@media screen and (max-width: 600px) {
    .display-4 {
        font-size: 2em !important;
        padding-top: 8rem;
    }
    /* .col-sm-8 {
        margin-top: 1.5rem !important;
    } */   
}


/* -------------- margen superior AboutUS -------------------------------*/
@media screen and (min-width: 1024px){
  .margenS{
    padding-top: 105px;
  }
}

@media (min-width: 768px) and (max-width: 1023px){
  .margenS{
    padding-top: 180px;
  }
}

@media (min-width: 577px) and (max-width: 992px){
  .margenS{
    padding-top: 25px;
  }
}
@media (min-width: 211px) and (max-width: 576px){
  .margenS{
    padding-top: 13px;
  }
}
/* ------------------------------------------------------------------------- */

/* ------------------------------------------------------------------------- */
.titleRedZone{
  margin-top: 60px !important;
}
.titleRedZone hr{
  border: 3px solid #0078C0;
  width: 55%;
}

.titleRedZone span{
  font-weight: bolder;
  font-size: 25px !important;
}

.descriptionRedZone{
  margin-top: 40px ;
  font-size: 20px !important;
  font-family: 'Helvetica';
}

.description2RedZone{
  font-size: 20px !important;
  text-align: justify;
}

/* boton solicitar Tarjeta */
.SolicitarTarjeta button{
  background: -webkit-linear-gradient(45deg, rgba(0,118,187,1) 0%, rgba(41,91,144,1) 100%);
  border-radius: 15px;
  color: white;
}

.SolicitarTarjeta button:hover{
  background: -webkit-linear-gradient(45deg, rgba(0,118,187,1) 0%, rgba(41,91,144,1) 100%);
  border-radius: 15px;
  color: white;
}

.seccionPedirTarjeta .row{
  justify-content: left;
}

.infoTarjeta span{
  font-size: 25px;
  font-weight: bold;
  color: #295b90
}

.infoTarjeta .descripcionTarjetaRedZone ul{
  font-size: 18px;
  font-weight: bold;
  color: #295b90;
  font-style: italic;
}

.infoTarjeta img{
  width: 10rem;
}

/*  */
.descripcionTarjetaRedZone ul .li-azul::marker{
  font-size: 40px !important;
  color: #2596be !important;
}

.descripcionTarjetaRedZone ul .li-rojo::marker{
  font-size: 50px !important;
  color: #cc2c14 !important;
}

.descripcionTarjetaRedZone ul .li-amarillo::marker{
  font-size: 25px !important;
  color: #e89c0c !important;
}
.bannerRedCompensas img{
  width: 100%;
  padding-left: auto !important;
  padding-right: auto !important;
}

.MenuEstadoDeCuenta .list-group{
  padding-top: 100px !important;
}

.list-group-item{
  background: #F2F2F2 !important;
  color: #4d4d4d !important;
}

.tab-content .tab-pane{
  background: #EFEFEF !important;
}

.DetallesEstadoDeCuenta{
  border: solid 2px #AFAFAF;
  border-radius: 10px !important;
}

.list-group-item.list-group-item-action-1.active{
  z-index: 2 !important;
  background-color: #EFEFEF !important;
  border-color: transparent !important;
  text-decoration: none !important;
  color: #0078c0 !important;
  font-weight: bolder !important;
}

.list-group-item.list-group-item-action-1{
  width: 100% !important;
  color:#4d4d4d !important;
  text-align: inherit !important;
  text-decoration: none !important;
  font-weight: bold !important;
  background: white !important;
}

#list-estado, #list-vitrina, #list-politicas, #list-aviso, #list-promos{
  padding-top: 60px !important;
}

.page-item.active .page-link{
  background: #0078c0 !important;
  border-color: #0078c0 !important;
}

@media screen and (max-width: 600px) {
    .display-4 {
        font-size: 2em !important;
        padding-top: 8rem;
    }
    /* .col-sm-8 {
        margin-top: 1.5rem !important;
    } */   
}


/* -------------- margen superior AboutUS -------------------------------*/
@media screen and (min-width: 1024px){
  .margenS{
    padding-top: 105px;
  }
}

@media (min-width: 768px) and (max-width: 1023px){
  .margenS{
    padding-top: 180px;
  }
}

@media (min-width: 577px) and (max-width: 992px){
  .margenS{
    padding-top: 25px;
  }
}
@media (min-width: 211px) and (max-width: 576px){
  .margenS{
    padding-top: 13px;
  }
}
/* ------------------------------------------------------------------------- */
/* ::-webkit-scrollbar {
  display: none;
} */

/*Clases Css nuevas */

@media (min-width: 992px) and  (max-width: 3500px) {
  .col-md-6-detail{
    min-height: 580px;
  }
}
@media (min-width: 778px) and  (max-width: 991px) {
  .col-md-6-detail{
    min-height: 480px;
  }
}

.details-content{
  margin-bottom: 25;
  margin-top: 10px;
  border: none;
  height: auto;
  overflow: auto;
  font-size: 20;
  max-height: 130px; 
  min-height: 110px;
}

.textLeft-padd{
  padding: 15px 0px 0px 0px;
  margin-bottom: 10;
}

.borderB-details{
  border-bottom: 7px solid #d2d2d2;
  margin-left: 2rem !important;
  margin-right: 2rem !important;
  margin-bottom: 2rem;
}
/* Fin Clases css nuevas*/



.none-scroll{
  overflow-x: hidden;
}

/* MEDIAS QUERY PARA HACER RESPONSIVE LA SECCION DE categorias en redPolar */
@media (min-width: 1920px) and (max-width: 3500px){
  .categoriasPolar{
    width: 1350px !important;
    height: 300px ;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) and (max-width:1919px){
  .categoriasPolar{
    width: 1100px !important;
    height: 300px ;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) and (max-width:1199px){
  .categoriasPolar{
    width: 910px !important;
    height: 300px ;
    margin-left: auto;
  }
}

@media (min-width: 768px) and (max-width:991px){
  .categoriasPolar{
    margin-top: 80px;
    width: 700px !important;
    height: 300px ;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 576px) and (max-width:767px){
  .categoriasPolar{
    margin-top: 50px;
    width: 500px !important;
    height: 300px ;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 211px) and (max-width:575px){
  .categoriasPolar{
    margin-top: 30px;
    width: 300px !important;
    height: 300px ;
    margin-left: auto;
    margin-right: auto;
  }
}

/* MEDIAS QUERY PARA HACER RESPONSIVE LA SECCION DE IMAGENES DE MARCAS */
@media (min-width: 1920px) and (max-width: 3500px){
    .marcas{
      height: 272px ;
    }
  }

  @media (min-width: 1200px) and (max-width:1919px){
    .marcas{
        height: 200px;
    }
  }

  @media (min-width: 992px) and (max-width:1199px){
    .marcas{
        height: 128px;
    }
  }

  /* ESTILOS PARA LA VISTA DETALLES */
  .geeks .slide img{
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .geeks .slide:hover img{
    opacity: 0.7;
  }

  @media (min-width: 1920px) and (max-width: 3500px){

    /* Linea de sepación del detalle del producto y su imagen*/
    .space-line{
      /* border-left: 7px solid #d2d2d2; */
      margin-bottom: 2rem;
      width: 96%;
    }
    .space-line .margin-line{
      margin-left: 2rem;
    }
    
    .botonRegresar h5{
      padding-top: 100px;
      margin-left: 250px;
    }

    .nombreArticulo{
      font-size: 40px;
      color: #000;
      max-width: 480px !important;
      text-transform: uppercase !important;
    }

    .IncluyeIva{
      font-size: 23px;
      color: #959595;
      margin-right: auto !important;
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    /* ESTILO DE LA SECCION DE DETALLES Y CARACTERÍSTICAS */
    .Descripciones{
      margin-right: auto !important;
      margin-left: 138px !important;
      width: 1646px !important;
      max-width: 1646px !important;
    }

    .Descripciones ul{
      height: 70px !important;
      border-bottom: none !important;
    }

    .Descripciones ul li{
      margin-top: auto !important;
      margin-bottom: auto !important;
      font-family: "Helvetica" !important;
      font-size: 22px ;
    }

    .geeks{
      width: 443px !important;
      height: 443px !important;
      max-width: 443px !important;
      max-height: 443px !important;
      border: solid 2px #ADADAD;
      border-radius: 15px !important;
    }

    .geeks .slide img{
      width: 400px !important;
      height: 400px !important;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      border-radius: 10px;
    }

    .contenidoCarousel img{
      margin-bottom: auto !important;
      margin-top: 10px !important;
    }

    .geeks .thumbs {
      justify-content: center !important;
      align-items: center !important;
      text-align: center;
      margin-top: 40px !important;
    }

    .thumb{
      border: solid 2px #ADADAD !important;
      border-radius: 10px !important;
      height: 80px !important;
      width: 80px !important;
    }

    .thumb select{
      border: solid 2px #666666 !important;
    }
    .carousel .control-dots .dot{
      background: #ADADAD !important;
    }

    .carousel-status{
      display: none !important;
    }

  }

  @media (min-width: 1200px) and (max-width:1919px){
    
    /* Linea de sepación del detalle del producto y su imagen*/
    .space-line{
      border-left: 7px solid #d2d2d2;
      margin-bottom: 2rem;
      width: 96%;
    }
    .space-line .margin-line{
      margin-left: 2rem;
    }
    
    .botonRegresar h5{
      padding-top: 90px;
      margin-left: 70px;
    }

    .nombreArticulo{
      font-size: 40px;
      color: #000;
      max-width: 480px !important;
    }

    .IncluyeIva{
      font-size: 21px;
      color: #959595;
      margin-right: auto !important;
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    /* ESTILO DE LA SECCION DE DETALLES Y CARACTERÍSTICAS */
    .Descripciones ul{
      width: 926px !important;
      max-width: 926px !important;
    }

    .Descripciones ul{
      height: 70px !important;
      border-bottom: none !important;
    }

    .Descripciones .tab-content{
      /*width: 926px !important;
      max-width: 926px !important;*/
      width: auto;
      max-width: auto;
      margin-right: 2rem;
      margin-left: 2rem;
      border: 2px solid #d2d2d2;
      border-radius: 1rem;
    }

    .Descripciones ul li{
      margin-top: auto !important;
      margin-bottom: auto !important;
      font-family: "Helvetica" !important;
      font-size: 22px ;
    }

    .geeks{
      width: 443px !important;
      height: 443px !important;
      max-width: 443px !important;
      max-height: 443px !important;
      border: solid 2px #ADADAD;
      border-radius: 15px !important;
    }

    .geeks .slide img{
      width: 400px !important;
      height: 400px !important;
    }

    .contenidoCarousel img{
      margin-bottom: auto !important;
      margin-top: 10px !important;
    }

    .geeks .thumbs {
      justify-content: center !important;
      align-items: center !important;
      text-align: center;
      margin-top: 40px !important;
    }

    .thumb{
      border: solid 2px #ADADAD !important;
      border-radius: 10px !important;
      height: 80px !important;
      width: 80px !important;
    }

    .thumb select{
      border: solid 2px #666666 !important;
    }
    .carousel .control-dots .dot{
      background: #ADADAD !important;
    }

    .carousel-status{
      display: none !important;
    }
  }

  @media (min-width: 992px) and (max-width:1199px){
    
    /* Linea de sepación del detalle del producto y su imagen*/
    .space-line{
      border-left: 7px solid #d2d2d2;
      margin-bottom: 2rem;
      width: 96%;
    }
    .space-line .margin-line{
      margin-left: 2rem;
    }
    
    .botonRegresar h5{
      padding-top: 50px;
      margin-left: 30px;
    }

    .nombreArticulo{
      font-size: 33px;
      color: #000;
      max-width: 430px !important;
    }

    .IncluyeIva{
      font-size: 21px;
      color: #959595;
      margin-right: auto !important;
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    /* ESTILO DE LA SECCION DE DETALLES Y CARACTERÍSTICAS */
    .Descripciones ul{
      width: 926px !important;
      max-width: 926px !important;
    }

    .Descripciones ul{
      height: 70px !important;
      border-bottom: none !important;
    }

    .Descripciones .tab-content{
      /*width: 890px !important;
      max-width: 890px !important;*/
      width: auto;
      max-width: auto;
      margin-right: 2rem;
      margin-left: 2rem;
      border: 2px solid #d2d2d2;
      border-radius: 1rem;
    }

    .Descripciones ul li{
      margin-top: auto !important;
      margin-bottom: auto !important;
      font-family: "Helvetica" !important;
      font-size: 22px ;
    }

    .geeks{
      width: 443px !important;
      height: 443px !important;
      max-width: 443px !important;
      max-height: 443px !important;
      border: solid 2px #ADADAD;
      border-radius: 15px !important;
    }

    .geeks .slide img{
      width: 400px !important;
      height: 400px !important;
    }

    .contenidoCarousel img{
      margin-bottom: auto !important;
      margin-top: 10px !important;
    }

    .geeks .thumbs {
      justify-content: center !important;
      align-items: center !important;
      text-align: center;
      margin-top: 40px !important;
    }

    .thumb{
      border: solid 2px #ADADAD !important;
      border-radius: 10px !important;
      height: 80px !important;
      width: 80px !important;
    }

    .thumb select{
      border: solid 2px #666666 !important;
    }
    .carousel .control-dots .dot{
      background: #ADADAD !important;
    }

    .carousel-status{
      display: none !important;
    }
  }

  @media (min-width: 768px) and (max-width:991px){
    
    /* Linea de sepación del detalle del producto y su imagen*/
    .space-line{
      border-left: 7px solid #d2d2d2;
      margin-bottom: 2rem;
      width: 96%;
    }
    .space-line .margin-line{
      margin-left: 2rem;
    }
    
    .botonRegresar h5{
      padding-top: 30px;
      margin-left: 30px;
    }

    .nombreArticulo{
      font-size: 33px;
      color: #000;
      max-width: 350px !important;
    }

    .IncluyeIva{
      font-size: 21px;
      color: #959595;
      margin-right: auto !important;
      margin-top: auto !important;
      margin-bottom: auto !important;
    }


    /* ESTILO DE LA SECCION DE DETALLES Y CARACTERÍSTICAS */
    .Descripciones ul{
      width: 710px !important;
      max-width: 710px !important;
    }

    .Descripciones ul{
      height: 70px !important;
      border-bottom: none !important;
    }

    .Descripciones .tab-content{
     /* width: 710px !important;
      max-width: 710px !important;*/
      width: auto;
      max-width: auto;
      margin-right: 2rem;
      margin-left: 2rem;
      border: 2px solid #d2d2d2;
      border-radius: 1rem;
    }

    .Descripciones ul li{
      margin-top: auto !important;
      margin-bottom: auto !important;
      font-family: "Helvetica" !important;
      font-size: 22px ;
    }

    .geeks{
      width: 350px !important;
      height: 350px !important;
      max-width: 350px !important;
      max-height: 350px !important;
      border: solid 2px #ADADAD;
      border-radius: 15px !important;
    }

    .geeks .slide img{
      width: 307px !important;
      height: 307px !important;
    }

    .contenidoCarousel img{
      margin-bottom: auto !important;
      margin-top: 10px !important;
    }

    .geeks .thumbs {
      justify-content: center !important;
      align-items: center !important;
      text-align: center;
      margin-top: 30px !important;
    }

    .thumb{
      border: solid 2px #ADADAD !important;
      border-radius: 10px !important;
      height: 80px !important;
      width: 80px !important;
    }

    .thumb select{
      border: solid 2px #666666 !important;
    }
    .carousel .control-dots .dot{
      background: #ADADAD !important;
    }

    .carousel-status{
      display: none !important;
    }
  }

  @media (min-width: 576px) and (max-width:767px){
      
    /* Linea de sepación del detalle del producto y su imagen*/
    .space-line{
      border-top: 7px solid #d2d2d2;
      margin-bottom: 2rem;
      width: 96%;
    }
    .space-line .margin-line{
      margin-top: 1rem;
    }
    
    .botonRegresar h5{
      padding-top: 50px;
      margin-left: 30px;
    }

    .nombreArticulo{
      font-size: 33px;
      color: #000;
      max-width: 350px !important;
    }

    .IncluyeIva{
      font-size: 21px;
      color: #959595;
      margin-right: auto !important;
      margin-top: auto !important;
      margin-bottom: auto !important;
    }


    /* ESTILO DE LA SECCION DE DETALLES Y CARACTERÍSTICAS */
    .Caracteristicas{
      padding-top: 100px;
      margin-left: 30px;
    }
    .Descripciones ul{
      width: 540px !important;
      max-width: 540px !important;
    }

    .Descripciones ul{
      height: 70px !important;
      border-bottom: none !important;
    }

    .Descripciones .tab-content{
     /* width: 540px !important;
      max-width: 540px !important;*/
      width: auto;
      max-width: auto;
      margin-right: 2rem;
      margin-left: 2rem;
      border: 2px solid #d2d2d2;
      border-radius: 1rem;
    }

    .Descripciones ul li{
      margin-top: auto !important;
      margin-bottom: auto !important;
      font-family: "Helvetica" !important;
      font-size: 22px ;
    }

    .geeks{
      width: 350px !important;
      height: 350px !important;
      max-width: 350px !important;
      max-height: 350px !important;
      border: solid 2px #ADADAD;
      border-radius: 15px !important;
    }

    .geeks .slide img{
      width: 307px !important;
      height: 307px !important;
    }

    .contenidoCarousel img{
      margin-bottom: auto !important;
      margin-top: 10px !important;
    }

    .geeks .thumbs {
      justify-content: center !important;
      align-items: center !important;
      text-align: center;
      margin-top: 30px !important;
    }

    .thumb{
      border: solid 2px #ADADAD !important;
      border-radius: 10px !important;
      height: 80px !important;
      width: 80px !important;
    }

    .thumb select{
      border: solid 2px #666666 !important;
    }
    .carousel .control-dots .dot{
      background: #ADADAD !important;
    }

    .carousel-status{
      display: none !important;
    }
  }

  @media (min-width: 211px) and (max-width:575px){
        
    /* Linea de sepación del detalle del producto y su imagen*/
    .space-line{
      border-top: 7px solid #d2d2d2;
      margin-bottom: 2rem;
      width: 96%;
    }
    .space-line .margin-line{
      margin-top: 1rem;
    }
    
    .botonRegresar h5{
      padding-top: 40px;
      margin-left: 20px;
    }

    .nombreArticulo{
      font-size: 30px;
      color: #000;
      max-width: 300px !important;
    }

    .IncluyeIva{
      font-size: 21px;
      color: #959595;
      margin-right: auto !important;
      margin-top: auto !important;
      margin-bottom: auto !important;
    }


    /* ESTILO DE LA SECCION DE DETALLES Y CARACTERÍSTICAS */
    .Caracteristicas{
      padding-top: 100px;
      margin-left: 20px;
    }
    .Descripciones ul{
      width: 310px !important;
      max-width: 310px !important;
    }

    .Descripciones ul{
      height: 70px !important;
      border-bottom: none !important;
    }

    .Descripciones .tab-content{
     /* width: 310px !important;
      max-width: 310px !important;*/
      font-size: 16px;
      width: auto;
      max-width: auto;
      margin-right: 2rem;
      margin-left: 2rem;
      border: 2px solid #d2d2d2;
      border-radius: 1rem;
    }

    .Descripciones ul li{
      margin-top: auto !important;
      margin-bottom: auto !important;
      font-family: "Helvetica" !important;
      font-size: 18px ;
    }

    .geeks{
      width: 310px !important;
      height: 310px !important;
      max-width: 310px !important;
      max-height: 310px !important;
      border: solid 2px #ADADAD;
      border-radius: 15px !important;
    }

    .geeks .slide img{
      width: 285px !important;
      height: 285px !important;
    }

    .contenidoCarousel img{
      margin-bottom: auto !important;
      margin-top: 10px !important;
    }

    .geeks .thumbs {
      justify-content: center !important;
      align-items: center !important;
      text-align: center;
      margin-top: 5px !important;
    }

    .thumb{
      border: solid 2px #ADADAD !important;
      border-radius: 10px !important;
      height: 80px !important;
      width: 80px !important;
    }

    .thumb select{
      border: solid 2px #666666 !important;
    }
    .carousel .control-dots .dot{
      background: #ADADAD !important;
    }

    .carousel-status{
      display: none !important;
    }
  }

  /* DISEÑO DEL CAMPO CANTIDAD Y BOTON AGREGAR AL CARRITO  */
  .nombreCaracteristica{
    color: rgb(0, 94, 173);
  }

  .caracteristica{
    color: rgb(0, 94, 173);
  }

  .cantidadDetalles input{
    /* border: transparent; */
    border-color: #afafaf;
    /* background: #ADADAD !important; */
    background: white !important;
    border-radius: 10px;
    color: rgb(0, 94, 173);
    font-family: 'Helvetica' !important;
    font-size: 23px;
    font-weight: bold !important;
    width: 80px !important;
    height: 48px !important;
  }

  /* .cantidadDetalles carousel-inner{
    margin: 20px auto;
    width: 443px;
    height: 443px;
  } */

  .cantidadDetalles button{
    color: white !important;
  }

  .cantidadDetalles input::-webkit-input-placeholder{
    font-style: normal !important;
  }

  .cantidadDetalles input:-ms-input-placeholder{
    font-style: normal !important;
  }

  .cantidadDetalles input::-ms-input-placeholder{
    font-style: normal !important;
  }

  .cantidadDetalles input::placeholder{
    font-style: normal !important;
  }

  .botonAgregarDetalles{
    padding-right: 0px !important;
  }

  .botonAgregarDetalles button{
    background: #89c33f;
    border: transparent;
    color: white;
    font-weight: bold;
    border-radius: 10px;
  }

  .botonAgregarDetalles button:hover{
    background: #89c33f;
    border: transparent;
    color: white;
    font-weight: bold;
    border-radius: 10px;
  }

  /* ESTILO DE LA SECCION DE DETALLES Y CARACTERÍSTICAS */
  /* .Descripciones{
    margin-right: auto !important;
    margin-left: 138px !important;
    width: 1646px !important;
    max-width: 1646px !important;
  }

  .Descripciones ul{
    height: 70px !important;
    border-bottom: none !important;
  }

  .Descripciones ul li{
    margin-top: auto !important;
    margin-bottom: auto !important;
    font-family: "Helvetica" !important;
    font-size: 22px ;
  } */

  /* esto es para dibujar la linea azul debajo del tab activado, ya sea descripcion o características */
  .nav-tabs .nav-link.active,
  .show>.nav-tabs .nav-link{
    border-bottom: solid 4px #005ead !important;
    background: transparent !important;
    color: #666666 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}
/* -------------------------------------------------------------------------------------------------- */

.Descripciones .nav-link{
  color: #666666 !important;
}

.tab-content{
  color: #4D4D4D;
  font-size: 22px;
}
  /* BORDE CARROUSEL */
  /* .carrouselImages{
    border: solid 2px #ADADAD;
    border-radius: 15px !important;
  } */

/* .geeks{
  width: 443px !important;
  height: 443px !important;
  max-width: 443px !important;
  max-height: 443px !important;
  border: solid 2px #ADADAD;
  border-radius: 15px !important;
}

.geeks .slide img{
  width: 400px !important;
  height: 400px !important;
}

.contenidoCarousel img{
  margin-bottom: auto !important;
  margin-top: 10px !important;
}

.geeks .thumbs {
  margin-top: 40px !important;
}

.thumb{
  border: solid 2px #ADADAD !important;
  border-radius: 10px !important;
  height: 80px !important;
  width: 80px !important;
}

.thumb select{
  border: solid 2px #666666 !important;
}
.carousel .control-dots .dot{
  background: #ADADAD !important;
}

.carousel-status{
  display: none !important;
} */

/* seccion de comentarios y calificacion del producto */
.califProducto{
  font-size: 64px;
  margin-bottom: 0 !important;
}

.promedioProducto{
  color: #959595;
  font-size: 20px;
}

.commentProduct{
  font-family: 'Helvetica';
}

.buttonEnviar button{
  background: #89c33f;
  border: transparent;
  color: white;
  font-weight: bold;
  border-radius: 10px;
}

.buttonEnviar button:hover{
  color: white;
}

.nuevoComentario input{
  border-color: #afafaf;
  background: white !important;
  border-radius: 10px;
  font-family: 'Helvetica' !important;
}

.nuevoComentario textarea{
  border-color: #afafaf;
  background: white !important;
  border-radius: 10px;
  font-family: 'Helvetica' !important;
}

.nuevoComentario label{
  font-size: 25px;
}

/* The Modal (background) */
.modalImagen .modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modalImagen .modal-content {
  margin: auto;
  margin-top: 250px !important;
  display: flex;
  width: 70%;
  max-width: 700px;
}

.modalImagen .modal img{
  border-radius: 80px !important;
}

/* Caption of Modal Image */
.modalImagen #caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modalImagen .modal-content, #caption {  
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

.modalImagen  .close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #f1f1f1 !important;
  font-size: 25px !important;
  font-weight: bold;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: black;
  border-radius: 10px !important;
  padding:4px;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)} 
  to {-webkit-transform:scale(1)};
}

@keyframes zoom {
  from {-webkit-transform:scale(0);transform:scale(0)} 
  to {-webkit-transform:scale(1);transform:scale(1)}
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modalImagen .modal-content {
    width: 100%;
  }
}
/* input[type="text"], select.form-control {
    position: absolute !important;
    top: 0 !important;
    right: 1rem !important;
    color: transparent !important;
    cursor: pointer !important;
    font-size: 2rem !important;
    -webkit-transition: .3s color !important;
    transition: .3s color !important;
} */

.form-control.form-control-lg.profileForm {
  background: transparent !important ;
  border: none !important ;
  box-shadow: none !important ;
  border-radius: 0 !important ;
  margin: 0;
  padding: 0;
}

.form-control.form-control-lg.profileForm:focus {
  box-shadow: none !important;
}

.borderRadius{
  border: solid 2px white;
  border-radius: 15px !important;
  background-color: white !important;
}

.cardWhite {
  background-color: white !important;
}

.form__labelProfile {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  margin-top: 0.7rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: left !important;
  padding-left: 0px !important;
}

.form-control.form-control-lg.profileForm.form__inputProfile {
  font-family: 'Roboto', sans-serif;
  color: #333;
  font-size: 1.2rem;
	margin: 0 auto;
  background-color: rgb(255, 255, 255);
  border: none;
  display: block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
/*
.form-control.form-control-lg.profileForm.form__inputProfile:placeholder-shown + .form__labelProfile {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-4rem);
  transform: translateY(-4rem);
}

.liTransition{
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
}

.liTransitionIcon{
  display: grid !important;
  grid-template-columns: 4.5fr 1fr !important;
}*/

@media screen and (min-width: 1200px){
  .margenSuperiorViewProfile{
    padding-top: 100px;
  }
}

@media (min-width: 992px) and (max-width: 1199px){
  .margenSuperiorViewProfile{
    padding-top: 45px;
  }
}

@media screen and (max-width: 991px){
  .margenSuperiorViewProfile{
    padding-top: 20px;
  }
}
.cabecera1{
    background-color:rgb(0, 94, 173);
    color: white !important;
}
#printSection .cabecera1{
    color: black;
}

#printSection .botonResumen{
    visibility: hidden !important;
}
.vista .encabezado{
    display: none !important;
}

#printSection .encabezado{
    display: block !important;
}
#printSection .vista{
    margin-left: '6 rem';
}

/* .encabezado{
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr !important;
} */
/* -------------- margen superior AboutUS -------------------------------*/
@media (min-width: 1920px) and (max-width: 3500px){
    .MargenSuperior{
      padding-top: 120px;
    }
  }
  
  @media (min-width: 1200px) and (max-width:1919px){
    .MargenSuperior{
      padding-top: 120px;
    }
  }
  
  @media (min-width: 992px) and (max-width:1199px){
    .MargenSuperior{
      padding-top: 100px;
    }
  }
  
  @media (min-width: 768px) and (max-width:991px){
    .MargenSuperior{
      padding-top: 60px;
    }
  }
  
  @media (min-width: 576px) and (max-width:767px){
    .MargenSuperior{
      padding-top: 50px;
    }
  }
  
  @media (min-width: 211px) and (max-width:575px){
    .MargenSuperior{
      padding-top: 30px;
    }
  }

  @media screen and (min-width: 1024px){
    .reports {
      font-size: 1.2rem;
    }
  }

.list-group-item{
  background: #F2F2F2;
}

.tab-pane{
  background: #ffffff !important;
}

.tab-content .tab-pane{
  background: #ffffff !important;
}


.list-group-item.active{
  z-index: 2 !important;
  color: #fff !important;
  background-color: #0060aa !important;
  border-color: #0060aa !important;
}
.list-group-item-action-1{
  width: 100% !important;
  color: #0060aa !important;
  text-align: inherit !important;
}
@media screen and (max-width: 600px) {
    .display-4 {
        font-size: 2em !important;
    }
    .col-sm-8 {
        margin-top: 1.5rem !important;
    }
}


/* -------------- margen superior AboutUS -------------------------------*/
@media (min-width: 1920px) and (max-width: 3500px){
  .margenS{
    padding-top: 80px;
  }

  .margenSuperiorMenuCategorias{
    margin-top: 150px;
  }
}

@media (min-width: 1200px) and (max-width:1919px){
  .margenS{
    padding-top: 60px;
  }

  .margenSuperiorMenuCategorias{
    margin-top: 150px;
  }
}

@media (min-width: 992px) and (max-width:1199px){
  .margenS{
    padding-top: 30px;
  }

  .margenSuperiorMenuCategorias{
    margin-top: 150px;
  }
}

@media (min-width: 768px) and (max-width:991px){
  .margenS{
    padding-top: 5px;
  }

  .margenSuperiorMenuCategorias{
    margin-top: 150px;
  }
}

@media (min-width: 576px) and (max-width:767px){
  .margenSuperiorMenuCategorias{
    margin-top: 150px;
  }
}

@media (min-width: 211px) and (max-width:575px){
  .margenSuperiorMenuCategorias{
    margin-top: 150px;
  }
}
/* ------------------------------------------------------------------------- */
.title{
  margin-top: 0px !important;
}
.title hr{
  border: 1px solid #005ead;
  width: 75%;
}

.title span{
  font-weight: bolder;
  font-size: 25px !important;
}

.description{
  margin-top: 40px ;
  font-size: 20px !important;
}

.description2{
  font-size: 20px !important;
  text-align: justify;
}
.description2 span{
  font-weight: bolder;
}

.list-group-item{
    background: #F2F2F2 !important;
  }
  
  .tab-pane{
    background: #fff !important;
  }
  .list-group-item.active{
    z-index: 2 !important;
    color: #fff !important;
    background-color:#024c92 !important;
    border-color: #024c92 !important;
  }
  .list-group-item-action-1{
    width: 100% !important;
    color: #024c92 !important;
    text-align: inherit !important;
  }
  @media screen and (max-width: 600px) {
      .display-4 {
          font-size: 2em !important;
      }
      .col-sm-8 {
          margin-top: 1.5rem !important;
      }
  }
/* -------------- margen superior AboutUS -------------------------------*/
@media (min-width: 1920px) and (max-width: 3500px){
    .margenSuperior{
      padding-top: 100px;
    }
  }
  
  @media (min-width: 1200px) and (max-width:1919px){
    .margenSuperior{
      padding-top: 100px;
    }
  }
  
  @media (min-width: 992px) and (max-width:1199px){
    .margenSuperior{
      padding-top: 30px;
    }
  }
  
  @media (min-width: 768px) and (max-width:991px){

  }
  
  @media (min-width: 576px) and (max-width:767px){

  }
  
  @media (min-width: 211px) and (max-width:575px){
    .margenSuperior{
      padding-top: 35px;
    }
  
  }

.active, .accordion:hover{
    background-color: #0078C0 !important;
}

.accordion .card-header span button{
  font-size: 25px !important;
}

.accordion .card-body{
  font-size: 20px !important;
}
/* input[type="text"], select.form-control {
    position: absolute !important;
    top: 0 !important;
    right: 1rem !important;
    color: transparent !important;
    cursor: pointer !important;
    font-size: 2rem !important;
    -webkit-transition: .3s color !important;
    transition: .3s color !important;
} */

[type=text].form-control.addressForm, select.form-control.addressForm {
    background: transparent !important ;
    border: none !important ;
    border-bottom: 1px solid #000000 !important ;
    box-shadow: none !important ;
    border-radius: 0 !important ;
  }

[type=text].form-control.addressForm:focus, select.form-control.addressForm:focus {
  box-shadow: none !important;
}

.form__label {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  margin-left: 2rem;
  margin-top: 0.7rem;
  display: block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translateY(0rem);
          transform: translateY(0rem);
}

.form__input {
  font-family: 'Roboto', sans-serif;
  color: #333;
  font-size: 1.2rem;
	margin: 0 auto;
  padding: 1.5rem 1rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #000000 !important ;
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-4rem);
  transform: translateY(-4rem);
}
@media screen and (min-width: 1024px){
  .pagoRealizado{
    font-size: 3.2rem;
    padding-top: 130px !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px){
  .pagoRealizado{
    font-size: 3.2rem;
    padding-top: 60px !important;
  }
}

@media (min-width: 320px) and (max-width: 767px){
  .pagoRealizado{
    font-size: 2.5rem !important;
    padding-top: 30px !important;
  }
}

.hides {
    display: none;
  }
  
  .hiddes {
    display: none;
  }
.tituloSafeShopping{
    font-family: 'Helvetica' !important;
}
.tituloSafeShopping span{
    color: #4D4D4D;
    font-size: 20px;
}

.pasosCompraSegura h5{
    font-weight: bold;
}

.pasosCompraSegura span{
    color: #4D4D4D;
    font-size: 18px;
    font-style: italic;
}
.tituloDevolution{
    font-family: 'Helvetica' !important;
    background: white;
    width: 100%;
}
.tituloDevolution span{
    color: #afafaf;
    font-size: 30px;
}

.devoluciones .card{
    border-radius: 15px;
    border: solid 1px #afafaf;
    height: 450px;
}

.devoluciones .card .card-header{
    background: transparent !important;
    border-bottom: none !important;
}

.devoluciones .card .card-body .contenido{
    font-size: 18px;
    color: #4D4D4D;
}

.NotaDevolution{
    font-family: 'Helvetica' !important;
    background: white;
}

.NotaDevolution span{
    color: #afafaf;
    font-size: 30px;
}

.NotaDevolution span strong{
    color: #4D4D4D;
}

/* responsivo de imagenes */
@media (min-width: 211px) and (max-width: 991px){
    .devoluciones .card img{
        max-width: 250px !important;
    }
}

@media screen and  (min-width: 720px){
    .NotaDevolution .nota{
        margin-left: 300px !important;

    }
}
.cardNewsBlog .card-footer{
    border-top: none !important;
}

.cardNewsBlog .card-footer button i{
    font-size: 45px;
    color: #666666 !important;
}

#datilsNewsBlog{
    font-family: 'Helvetica' !important;
} 

#datilsNewsBlog .modal-body img{
    max-width: 1000px;
}

#datilsNewsBlog .modal-body .tituloNewsBlogModal{
    font-size: 28px;
    color: #666666;
    font-weight: bolder !important;
}
.tituloC {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: rgb(0,94,173);
}
