/* -------------- margen superior AboutUS -------------------------------*/
@media (min-width: 1920px) and (max-width: 3500px){
    .MargenSuperior{
      padding-top: 120px;
    }
  }
  
  @media (min-width: 1200px) and (max-width:1919px){
    .MargenSuperior{
      padding-top: 120px;
    }
  }
  
  @media (min-width: 992px) and (max-width:1199px){
    .MargenSuperior{
      padding-top: 100px;
    }
  }
  
  @media (min-width: 768px) and (max-width:991px){
    .MargenSuperior{
      padding-top: 60px;
    }
  }
  
  @media (min-width: 576px) and (max-width:767px){
    .MargenSuperior{
      padding-top: 50px;
    }
  }
  
  @media (min-width: 211px) and (max-width:575px){
    .MargenSuperior{
      padding-top: 30px;
    }
  }

  @media screen and (min-width: 1024px){
    .reports {
      font-size: 1.2rem;
    }
  }