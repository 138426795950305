.cardNewsBlog .card-footer{
    border-top: none !important;
}

.cardNewsBlog .card-footer button i{
    font-size: 45px;
    color: #666666 !important;
}

#datilsNewsBlog{
    font-family: 'Helvetica' !important;
} 

#datilsNewsBlog .modal-body img{
    max-width: 1000px;
}

#datilsNewsBlog .modal-body .tituloNewsBlogModal{
    font-size: 28px;
    color: #666666;
    font-weight: bolder !important;
}