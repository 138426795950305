.sans-serif { font-family: Helvetica, 'Helvetica next', helvetica, arial, sans-serif; }
/* SECCION DE CONTACTO --BARRA AZUL*/
.contact {
  height: 44.5px;
  /* background: -webkit-linear-gradient(45deg, rgba(0,118,187,1) 0%, rgba(41,91,144,1) 100%); */
  background: rgb(197, 88, 48);
}

.text-navbar {
  font-weight: bold;
  font-size: 1.2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.text-navbar:hover{
  background-color: #efefef !important;
  color: #0078C0 !important;
}

@media (min-width: 768px) { 
  .text-navbar {
    font-weight: bold;
    font-size: 1.2rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }
}
 
@media (min-width: 1024px) {
  .text-navbar {
    font-weight: bold;
    font-size: 1.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
 }

@media (min-width: 1920px) and (max-width: 3500px){
  .contact .phone{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 40px;
    margin-left: auto;
    font-family: 'Helvetica';
    font-size: 17pt;
  }

  .contact .suc{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 136.3px !important;
    font-family: 'Helvetica';
    font-size: 17pt;
  }
}

@media (min-width: 1200px) and (max-width:1919px){
  .contact .phone{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 32.8px;
    margin-left: auto;
    font-family: 'Helvetica';
    font-size: 17pt;
  }

  .contact .suc{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 64.3px;
    font-family: 'Helvetica';
    font-size: 17pt;
  }
}

@media (min-width: 992px) and (max-width:1199px){
  .contact .phone{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 32.8px;
    margin-left: auto;
    font-family: 'Helvetica';

  }

  .contact .suc{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 64.3px;
    font-family: 'Helvetica';

  }
}

@media (min-width: 768px) and (max-width:991px){
  .contact .phone{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 32.8px;
    margin-left: auto;
    font-family: 'Helvetica';
    font-size: 14pt;
  }

  .contact .suc{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 64.3px;
    font-family: 'Helvetica';
    font-size: 14pt;
  }
}

@media (min-width: 576px) and (max-width:767px){
  .contact{
    height: 60px;
    display: grid !important;
    grid-template-rows: 1fr 1fr !important;
    font-family: 'Helvetica';
    font-size: 14pt;
  }

  .contact .phone{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 32.8px;
    text-align: center;
    font-family: 'Helvetica';
    font-size: 14pt;
  }

  .contact .suc{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 64.3px;
    text-align: center;
  }
}

@media (min-width: 211px) and (max-width:575px){
  .contact{
    height: 60px;
    display: grid !important;
    grid-template-rows: 1fr 1fr !important;
    font-family: 'Helvetica';
    font-size: 14pt;
  }

  .contact .phone{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 32.8px;
    margin-left: auto;
    font-size: 14pt;
  }

  .contact .suc{
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: auto;
  }
}

/* FIRST NAVBAR */
.imgAvatar img{
  width: 60px !important;
  height: 60px !important;
}
.elements .shooping span{
  vertical-align: middle !important;
}

.shopp .ico-nav-shearch{
  vertical-align: middle !important;
}

.shooping img{
  width: 55px;
}

@media (min-width: 1920px) and (max-width: 3500px){
  .primerNav{
    height: 128px;
  }

  .elements .menu-categories-button{
    margin-left: auto !important;
  }

  .elements .shooping{
    margin-right: auto !important;
  }

  .elements .menu-categories-button #buttonMenu{
    width: 65.596px !important;
    height: 48.283px  !important;
    margin-right: 5px;
  }

  .menu-categories-button p img{
    width: 237.146px !important;
    height: 82.199px  !important;
  }

  .menu-categories-button p{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important; 
  }

  .shooping{
    /* width: 58.55px;
    height: 58.55px;
    border: 3px solid -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    border-radius: 50%;
    background: -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    color: white;
    padding-top: 15px;
    padding-left: 15px; */
  }

  #IconUser{
    width: 58.55px;
    height: 58.55px;
    border: 3px solid #8c8c8c !important;
    border-radius: 50%;
    background: #8c8c8c !important;
    color: white;
    padding-top: 13px;
  }
  
  .elements li{
    margin-top: auto !important;
    margin-bottom: auto;
  }
  
  .NombreUsuario{
    text-align: left;
  }

  .buttonSearchAdvanced img{
    display: none;
  }

  .searchItems{
    display:block;
    }
}

@media (min-width: 1200px) and (max-width:1919px){
  .primerNav{
    height: 128px;
  }

  .elements .menu-categories-button{
    margin-left: auto !important;
  }

  .elements .shooping{
    margin-right: auto !important;
  }
  
  .elements .menu-categories-button #buttonMenu{
    width: 61.536px !important;
    height: 46.536px  !important;
    margin-right: 8.8px;
  }

  .menu-categories-button p img{
    width: 183.146px !important;
    height: 70.199px  !important;
  }

  .shooping{
    /* width: 51.35px;
    height: 51.35px;
    border: 2.28px solid -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    border-radius: 50%;
    background: -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    color: white;
    padding-top: 10px;
    padding-left: 10px; */
  }

  #IconUser{
    width: 51.35px;
    height: 51.35px;
    border: 2.28px solid #8c8c8c !important;
    border-radius: 50%;
    background: #8c8c8c !important;
    color: white;
    padding-top: 5.8px;
  }
  
  .elements li{
    margin-top: auto !important;
    margin-bottom: auto;
  }
  
  .NombreUsuario{
    text-align: left;
  }

  .buttonSearchAdvanced img{
    display: none;
  }

  .searchItems{
    display:block;
    }
}

@media (min-width: 992px) and (max-width:1199px){
  .primerNav{
    height: 90px;
  }

  .elements .menu-categories-button{
    margin-left: auto !important;
  }

  .elements .shooping{
    margin-right: auto !important;
  }
  
  .elements .menu-categories-button #buttonMenu{
    width: 43.083px !important;
    height: 31.083px  !important;
    margin-right: 5px;
  }

  .menu-categories-button p img{
    width: 145.146px !important;
    height: 52.999px  !important;
  }

  .shooping{
    /* width: 51.35px;
    height: 51.35px;
    border: 2.28px solid -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    border-radius: 50%;
    background: -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    color: white;
    padding-top: 10px;
    padding-left: 10px;
    margin-top: auto !important;
    margin-bottom: auto !important; */
  }

  #IconUser{
    width: 51.35px;
    height: 51.35px;
    border: 2.28px solid #8c8c8c !important;
    border-radius: 50%;
    background: #8c8c8c !important;
    color: white;
    padding-top: 5.8px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elements li{
    margin-top: auto !important;
    margin-bottom: auto;
  }
  
  .NombreUsuario{
    text-align: left;
  }

  .buttonSearchAdvanced img{
    display: none;
  }

  .searchItems{
    display:block;
    }
}

@media (min-width: 768px) and (max-width:991px){
  .primerNav{
    height: 80px;
  }
  
  .elements .menu-categories-button{
    margin-left: auto !important;
  }

  .elements .shooping{
    margin-right: auto !important;
  }

  .elements .menu-categories-button #buttonMenu{
    width: 40.336px !important;
    height: 29.336px  !important;
    margin-right:5px;
  }

  .elements .menu-categories-button{
    padding-right: 4px !important;
    padding-left: 0px !important;
  }

  .elements .navbar-brand{
    margin-right: 3px !important;
  }

  .menu-categories-button p img{
    width: 138px !important;
    height: 50px  !important;
  }

  .shooping{
    /* width: 51.35px;
    height: 51.35px;
    border-radius: 50%;
    background: -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    color: white;
    padding-top: 12px;
    padding-left: 10px;
    margin-top: auto !important;
    margin-bottom: auto !important; */
  }

  #IconUser{
    width: 51.35px;
    height: 51.35px;
    border-radius: 50%;
    background: #8c8c8c !important;
    color: white;
    padding-top: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elements li{
    margin-top: auto !important;
    margin-bottom: auto;
  }
  
  .NombreUsuario{
    text-align: left;
  }
}

@media (min-width: 645px) and (max-width:767px){
  .elements{
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr !important;
  }

  .elements .menu-categories-button{
    margin-left: auto !important;
  }

  .elements .shooping{
    margin-right: auto !important;
  }

  .elements .menu-categories-button #buttonMenu{
    width: 50px !important;
    height: 38px  !important;
  }

  .menu-categories-button p img{
    width: 130px !important;
    height: 49px  !important;
  }

  .shooping img{
    width: 45px;
  }

  .shooping{
    /* width: 51.35px;
    height: 51.35px;
    border: 2.28px solid -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    border-radius: 50%;
    background: -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    color: white;
    padding-top: 10px;
    padding-left: 10px;
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-left:8px; */
  }
  
  .elements li{
    margin-top: auto !important;
    margin-bottom: auto;
  }

  .buttonSearchAdvanced img{
    display: block;
    width: 80% !important;
    margin-right: 15%
  }
}

@media (min-width: 576px) and (max-width:644px){
  .elements{
    display: grid;
    grid-template-columns: auto auto auto !important;
  }

  .elements .menu-categories-button{
    margin-left: auto !important;
  }

  .elements .shooping{
    margin-right: auto !important;
  }

  .elements .menu-categories-button{
    margin-left: 1px !important;
  }

  .elements .shooping{
    margin-right: 1px !important;
  }

  .elements .menu-categories-button #buttonMenu{
    width: 40px !important;
    height: 30px  !important;
  }

  .menu-categories-button p img{
    width: 120px !important;
    height: 43px  !important;
  }

  .shooping img{
    width: 45px;
  }

  .shooping{
    /* width: 51.35px;
    height: 51.35px;
    border: 2.28px solid -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    border-radius: 50%;
    background: -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    color: white;
    padding-top: 10px;
    padding-left: 10px;
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-left:8px; */
  }
  
  .elements li{
    margin-top: auto !important;
    margin-bottom: auto;
  }

  .buttonSearchAdvanced img{
    display: block;
    width: 80% !important;
    margin-right: 15%
  }
}

@media (min-width: 300px) and (max-width:575px){
  .elements{
    display: grid !important;
    grid-template-columns: auto auto auto !important;
  }
  .elements{
    margin-left: 5px !important;
  }

  .shooping img{
    width: 37px;
  }

  .elements .shooping{
    margin-right: 50px !important;
  }
  
  .elements .shooping{
    margin-right: auto !important;
  }

  .elements .navbar-brand{
    margin-right: 2px !important;
    margin-left: 2px !important;
  }

  .elements .menu-categories-button #buttonMenu{
    width: 30px !important;
    height: 25px  !important;
  }

  .menu-categories-button p img{
    width: 80px !important;
    height: 30px;
    margin: 0rem !important;
    padding: 0rem !important;
  }

  .shooping{
    /* width: 35.35px;
    height: 35.35px;
    border-radius: 50%;
    background: -webkit-linear-gradient(left, rgba(0,119,193,1) 0%, rgba(17,89,143,1) 100%) !important;
    color: white;
    padding-left: 1px;
    padding-top: 5px;
    margin-top: auto !important;
    margin-bottom: auto !important; */
  }

  .shooping{
    font-size: 1.5rem !important;
  }
  
  .elements li{
    margin-top: auto !important;
    margin-bottom: auto;
  }

  .buttonSearchAdvanced img{
    display: block;
    width: 100px!important;
  }

  .ico-nav-shearch{
    font-size: 1.5rem !important;
  }
}


.respons{
  padding-right: 0 !important;
  display: inline-block !important;
  text-align: center;
}
/* @media (min-width: 211px) and (max-width:360px){
  .ico-nav-shearch{
    font-size: 1.4rem !important;
  }

  .shooping{
    font-size: 1.4rem !important;
  }

  .buttonSearchAdvanced img{
    width: 88px;
  }
} */

/* @media (min-width: 361px) and (max-width:415px){
  .ico-nav-shearch{
    font-size: 1.5rem !important;
  }

  .shooping{
    font-size: 1.5rem !important;
  }

  .buttonSearchAdvanced img{
    width: 100px;
  }

} */

/* @media (min-width: 416px) and (max-width:575px){
  .ico-nav-shearch{
    font-size: 1.9rem !important;
  }

  .shooping{
    font-size: 1.9rem !important;
  }

  .buttonSearchAdvanced img{
    width: 120px;
  }

} */


/* @media (min-width: 280px) and (max-width: 576px){
  .elements{
    display: grid !important;
    grid-template-columns: 2fr 2fr 2fr  !important;
  }
} */

/* @media screen and (max-width: 768px) {
  .searchItems{
  display:none;
  }
} */

/* SEGUNDA NAV */
.navbar-two .menuImages li img{
  /* vertical-align: sub !important; */
  margin-top: auto !important;
}
.navbar-two .menuImages li{
  margin-top: auto !important;
}

@media screen and (max-width: 991px){
  .miCuenta{
      display: none !important;
  }
}

@media screen and (max-width: 991px){
  ul .miCuenta{
    display: none !important;
  }
}

@media (min-width: 1920px) and (max-width: 3000px){
  .navbar-two {
    width:1192.6x !important;
  }

  .navbar-two .menuImages{
    /* margin-top: auto !important; */
    /* margin-bottom: auto !important; */
  }

  .navbar-two li img{
    max-width: 200px;
    max-height: 60px;
  }
}

@media (min-width: 1200px) and (max-width:1919px){
  .navbar-two {
    width:1192.6x !important;
    height: 60px;
  }
  .navbar-two .menuImages li{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .navbar-two .menuImages img{
    width: 170px;
  }
}

@media (min-width: 992px) and (max-width:1199px){
  .navbar-two {
    width:1192.6x !important;
    height: 60px;
  }
  .navbar-two .menuImages li{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .navbar-two .menuImages img{
    width: 100px;
  }

}
/* @media (min-width: 1030px) and (max-width: 1110px){
  .navbar-two img{
    width: 170px !important;
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}

@media (min-width: 992px) and (max-width: 1029px){
  .navbar-two img{
    width: 160px !important;
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
} */

/* .nav{
    @media (min-width: 428px) and (max-width:1200px) {
        margin-left:10;
        padding-left: 2 rem;
        align-self: right;
        display: inline;
        padding: left 0;
        margin-right: 80;

    }
} */

/* MENU CATEGORIAS */
@media (min-width: 1920px) and (max-width:3000){
  #menuCategories{
    padding-top: 150px !important;
  }
}

@media (min-width: 1200px) and (max-width:1919px){
  #menuCategories{
    padding-top: 150px !important;
  }
}

@media (min-width: 992px) and (max-width:1199px){
  #menuCategories{
    padding-top: 100px !important;
  }
}

@media (min-width: 768px) and (max-width:991px){
  #menuCategories{
    padding-top: 77px !important;
  }
}

@media (min-width: 645px) and (max-width:767px){
  #menuCategories{
    padding-top: 70px !important;
  }
}

@media (min-width: 576px) and (max-width:644px){  
  #menuCategories{
    padding-top: 60px !important;
  }
}

@media (min-width: 300px) and (max-width:575px){
  #menuCategories{
    padding-top: 45px !important;
  }
}


/* @media (min-width: 992px) and (max-width: 2500px){
  #menuCategories{
    padding-top: 67px;
  }
}

@media (min-width: 768px) and (max-width: 991px){
  #menuCategories{
    padding-top: 68px;
  }
}

@media (min-width: 320px) and (max-width: 767px){
  #menuCategories{
    padding-top: 68px;
  }
} */

@media (max-width: 767px) {
    .hidden-mobile {
      display: none;
    }
  }
  
.c{
    border-color:red ;
}

@media screen and (min-width: 1024px) {
  .marq {
      width: 120px;
      margin: auto; 
      display: inline-block;
      overflow: hidden; 
      white-space: nowrap; 
      color: #0078C0 !important;
      font-weight: bold !important;
  }
}

@media (min-width: 992px) and (max-width: 1023px){
  .marq {
      width: 120px;
      margin: auto; 
      display: inline-block;
      overflow: hidden; 
      white-space: nowrap; 
      color: #0078C0 !important;
      font-weight: bold !important;
  }
}

@media (min-width: 768px) and (max-width: 991px){
  .marq {
      width: 100px;
      margin: auto; 
      display: inline-block;
      overflow: hidden; 
      white-space: nowrap; 
      color: #0078C0 !important;
      font-weight: bold !important;
  }
}

.orig {
  display: inline-block; 
  transform: translatex(0); 
  animation: marquesina 10s infinite normal linear 1s; 
}

@keyframes marquesina {
  0% {transform: translatex(0); }
  50% {transform: translatex(-30%); }
}

/* modal búsqueda avanzada */
#searchModal .modal-header{
  color: white !important;
}

/* --------------------------------------- */

@media screen and (min-width:576px){
  .shooping .cont-cart{
    font-weight: normal !important;
    font-size: 2rem !important;
    padding-left: 8px;
  }  
}

@media screen and (max-width:575px){
  .shooping .cont-cart{
    font-weight: normal !important;
    font-size: 1.5rem !important;
    padding-left: 2px;
  }  
}