.tituloSafeShopping{
    font-family: 'Helvetica' !important;
}
.tituloSafeShopping span{
    color: #4D4D4D;
    font-size: 20px;
}

.pasosCompraSegura h5{
    font-weight: bold;
}

.pasosCompraSegura span{
    color: #4D4D4D;
    font-size: 18px;
    font-style: italic;
}