.scroll-container{
    display: block !important;
    width: 350px !important;
    height: 200px !important;
    overflow-y: scroll !important;
    scroll-behavior: smooth !important;
  }

  #scroll-container{
    display: block !important;
    width: 350px !important;
    height: 200px !important;
    overflow-y: scroll !important;
    scroll-behavior: smooth !important;
  }