
.list-group-item{
  background: #F2F2F2;
}

.tab-pane{
  background: #ffffff !important;
}

.tab-content .tab-pane{
  background: #ffffff !important;
}


.list-group-item.active{
  z-index: 2 !important;
  color: #fff !important;
  background-color: #0060aa !important;
  border-color: #0060aa !important;
}
.list-group-item-action-1{
  width: 100% !important;
  color: #0060aa !important;
  text-align: inherit !important;
}
@media screen and (max-width: 600px) {
    .display-4 {
        font-size: 2em !important;
    }
    .col-sm-8 {
        margin-top: 1.5rem !important;
    }
}


/* -------------- margen superior AboutUS -------------------------------*/
@media (min-width: 1920px) and (max-width: 3500px){
  .margenS{
    padding-top: 80px;
  }

  .margenSuperiorMenuCategorias{
    margin-top: 150px;
  }
}

@media (min-width: 1200px) and (max-width:1919px){
  .margenS{
    padding-top: 60px;
  }

  .margenSuperiorMenuCategorias{
    margin-top: 150px;
  }
}

@media (min-width: 992px) and (max-width:1199px){
  .margenS{
    padding-top: 30px;
  }

  .margenSuperiorMenuCategorias{
    margin-top: 150px;
  }
}

@media (min-width: 768px) and (max-width:991px){
  .margenS{
    padding-top: 5px;
  }

  .margenSuperiorMenuCategorias{
    margin-top: 150px;
  }
}

@media (min-width: 576px) and (max-width:767px){
  .margenSuperiorMenuCategorias{
    margin-top: 150px;
  }
}

@media (min-width: 211px) and (max-width:575px){
  .margenSuperiorMenuCategorias{
    margin-top: 150px;
  }
}
/* ------------------------------------------------------------------------- */
.title{
  margin-top: 0px !important;
}
.title hr{
  border: 1px solid #005ead;
  width: 75%;
}

.title span{
  font-weight: bolder;
  font-size: 25px !important;
}

.description{
  margin-top: 40px ;
  font-size: 20px !important;
}

.description2{
  font-size: 20px !important;
  text-align: justify;
}
.description2 span{
  font-weight: bolder;
}