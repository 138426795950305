.BreakWord{
    display: inline-block;
    word-break: break-word;
}

@media only screen and (max-width: 825px){
    .breadcrumb{
        font-size: 20px !important;
    }
}
