.respons{
  padding-right: 0 !important;
  display: inline-block !important;
  text-align: center;
}

@media (min-width: 577px) and (max-width: 767px){
  .elements{
    display: grid !important;
    grid-template-columns: 1fr  1fr !important;
    font-size: small;
  }
}

@media (min-width: 211px) and (max-width: 576px){
  .elements{
    display: grid !important;
    grid-template-columns: 1fr  1fr !important;
    font-size: small;
  }
  .elements i{
    font-size: 1.5rem !important;
  }
}
/* tamaño responsivo del botòn del segundo menú */
@media (min-width: 211px) and (max-width: 576px){
  .secondmenu i{
    font-size: 2.1rem !important;
  }
}

@media (min-width: 211px) and (max-width: 575px) {
  .menu-categories-button i{
      padding-left: 10px !important;
  }
}

@media (min-width: 211px) and (max-width: 767px) {
  .shoppingSearch{
      padding-right: 1px !important;
  }
}

@media (min-width: 768px) {
  .shoppingSearch{
      margin-right: 12px !important;
  }
}

@media screen and (max-width: 768px) {
  .searchItems{
  display:none;
  }
}

@media screen and (min-width: 768px){
  .searchItems i{
  font-size: 25px !important;
  }
}


@media screen and (max-width: 768 px){
  #iconoSearch{
    display: none;
  }
}

/* @media screen and (max-width: 768px) {
  .categorias{
  display:none;
  }
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#alerta {  
  background: rgb(255, 0, 43);
  opacity:1;
  transition: opacity .5s;
}

#alerta.fade {
  opacity:0;
}


.sticky-column {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 1;
  width: 100px; /* How can I make this dynamic? */
}

/* Encabezado fijo de tablas*/
.scroll {
  border: 0;
  border-collapse: collapse;
}

.scroll tr {
  display: flex;
}

.scroll td {
  padding: 3px;
  flex: 1 auto;
  word-wrap: break;
}

.scroll thead tr:after {
  content: '';
  overflow-y: scroll;
  visibility: hidden;
  height: 0;
}

.scroll thead th {
  flex: 1 auto;
  display: block;
}

.scroll tbody {
  display: block;
  width: 100%;
  overflow-y: auto;
  height: 400px;
}


/*ENCABEZADO FIJO DE TABLAS VISTA PROMOCIONES- TABLA VENTAS Y BONIFICACIÓN*/
/* .scrolltable thead tr th { 
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #2d75bd;
}

.scrolltable table-responsive { 
  height:200px;
  overflow:scroll;
} */


/* .scrollPromo {
  border: 0;
  border-collapse: collapse;
}

.scrollPromo tr {
  display: flex;
} 

.scrollPromo td {
  padding: 3px;
  flex: 1 auto;
  word-wrap: break;
}

.scrollPromo thead tr:after {
  content: ''; 
  overflow-y: scroll;
   visibility: hidden; 
   height: 0; 
}

.scrollPromo thead th {
  flex: 1 auto; 
  display: block;
}

.scrollPromo tbody {
  width: 100%;
  display: block;
  overflow-y: auto;
  height: 290px;
}  */

.modal #HeaderModalVenta{
  cursor: move;
}

.details{
  margin: 0;
  background-color: white;
  text-align: center;
  color: #f44336 !important;
  padding: 3px;
  font-weight: bold;  cursor:pointer;
  font-size: medium;
  display: flex;
  justify-content: center;
}


.popsDetails {
  background-color: blue !important;
  width: 16px;
  height: 18px;
  color: #f44336 !important;
}

/* responsivo de imagenes e iconos del Footer */
@media (min-width: 211px) and (max-width: 767px){
  .styleFooter img{
    width: 25% !important;
    height: 15% !important;
  }

  .styleFooter i{
    font-size: 1.3rem !important;
  }

}

/* detalles de los items en la modal */


@media (min-width: 992px) and (max-width: 1022px){
  .detailisModal{
    height: 150px !important;
  }
}

@media (min-width: 1023px) and (max-width: 2024px){
  .detailisModal{
    height: 240px !important;
  }
}

@media (min-width: 1300px) and (max-width: 1520px){
  .detailisModal{
    height: 300px !important;
  }
}

@media (min-width: 1521px){
  .detailisModal{
    height: 400px !important;
  }
}