@media (min-width: 1920px) and (max-width: 3000px){
    .inputSearchItems{
        width: 884.872px !important;
        height: 68.566px !important; 
    }

    .inputSearchItems input::placeholder{
        font-style: normal !important;
        color: #ced4da !important;
        font-family: 'Helvetica', 'Heavy';

    }
    
    .inputSearchItems input{
        border-top-left-radius: 15px !important;
        border-bottom-left-radius: 15px !important;
        font-family: 'Helvetica';

    }

    .buttonIconSearch i{
        width: 35.246px;
    }

    .buttonAvanzada{
        border-top-right-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
        text-align: center;
        font-family: 'Helvetica';

    }

    .divisior::before{
        position:absolute;
        content: "";
        z-index: 9;
        border-left: 3px solid rgba(34,36,38,.15);
        height: calc(90% - 1.8rem);
    }

    .divisior::before{
        top: 25% !important;
    }
}

@media (min-width: 1200px) and (max-width: 1919px){
    .inputSearchItems{
        width: 500.872px !important;
        height: 61.366px !important; 
    }

    .inputSearchItems input::placeholder{
        font-style: normal !important;
        color: #ced4da !important;
    }
    
    .inputSearchItems input{
        border-top-left-radius: 15px !important;
        border-bottom-left-radius: 15px !important;
    }

    .buttonIconSearch i{
        width: 35.246px;
    }

    .buttonAvanzada{
        border-top-right-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
        text-align: center;
    }

    .divisior::before{
        position:absolute;
        content: "";
        z-index: 9;
        border-left: 3px solid rgba(34,36,38,.15);
        height: calc(90% - 1.8rem);
    }

    .divisior::before{
        top: 25% !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px){
    .inputSearchItems{
        width: 428.872px !important;
        height: 54.166px !important;
        vertical-align: middle !important;
    }

    .inputSearchItems input::placeholder{
        font-style: normal !important;
        color: #ced4da !important;
    }
    
    .inputSearchItems input{
        border-top-left-radius: 15px !important;
        border-bottom-left-radius: 15px !important;
        height: 50px !important;
    }

    .buttonIconSearch{
        height: 50px !important; 
    }

    .buttonIconSearch i{
        font-size: 1.5rem;
    }

    .buttonAvanzada{
        border-top-right-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
        text-align: center;
        height: 50px !important;
    }

    /* .divisior::before{
        position:absolute;
        content: "";
        z-index: 9;
        border-left: 3px solid rgba(34,36,38,.15);
        height: calc(90% - 1.8rem);
    }

    .divisior::before{
        top: 35% !important;
    } */
}

@media (min-width: 768px) and (max-width: 991px){
    .inputSearchItems{
        width: 256.872px !important;
        margin-right: auto;
        margin-left: auto;
    }

    .inputSearchItems input::placeholder{
        font-style: normal !important;
        color: #ced4da !important;
    }
    
    .inputSearchItems input{
        border-top-left-radius: 15px !important;
        border-bottom-left-radius: 15px !important;
        height: 50px !important;
    }

    .buttonIconSearch i{
        font-size: 1.2rem;
    }

    .buttonAvanzada{
        border-top-right-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
        text-align: center;
    }

    /* .divisior::before{
        position:absolute;
        content: "";
        z-index: 9;
        border-left: 3px solid rgba(34,36,38,.15);
        height: calc(90% - 1rem);
    }

    .divisior::before{
        top: 25% !important;
    } */
}

